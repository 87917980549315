import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { uploadDrawing } from '../../../redux/action/drawingAction';

const UploadDrawing = ({ uploadDrawing }) => {
  const [image, setImage] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    material: '',
    format: '',
    originalPrice: '',
  });

  const { title, material, format, originalPrice } = formData;

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const hanldeUploadChange = (e) => {
    setImage(e.target.files[0]);
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    uploadDrawing(formData, image);
    setFormData({
      title: '',
      material: '',
      format: '',
      originalPrice: '',
    });
    setImage(null);
  };
  return (
    <div className='dashboard-form'>
      <Link className='btn btn-light' to='/dashboard'>
        Zurück
      </Link>
      <h1 className='large text-center'>Neue Zeichnung hochladen</h1>
      <form className='form' onSubmit={onFormSubmit}>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Title'
            name='title'
            value={title}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Material'
            name='material'
            value={material}
            onChange={(e) => onChange(e)}
          ></input>
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Format'
            name='format'
            value={format}
            onChange={(e) => onChange(e)}
          ></input>
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Original Preis'
            name='originalPrice'
            value={originalPrice}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <input
            type='file'
            name='imageURL'
            id='imageURL'
            onChange={(e) => hanldeUploadChange(e)}
            hidden
          />
          <label htmlFor='imageURL' className='btn btn-dark'>
            Zeichnung auswählen
          </label>
          <span id='file-chosen'>
            {image === null ? 'Keine Zeichnung ausgewählt' : image.name}
          </span>
        </div>
        <input type='submit' className='btn btn-primary' value='Senden' />
      </form>
    </div>
  );
};

export default connect(null, { uploadDrawing })(UploadDrawing);
