import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

const Book = ({ book, match }) => {
  return book !== null ? (
    <Fragment>
      <div className='book-details'>
        <div className='book-img'>
          <img src={book.imageURL} alt='cover' />
        </div>
        <div className='book-content'>
          <h1>{book.title}</h1>
          <p>{book.description}</p>
          <Link
            to={`/leseprobe/${match.params.id}`}
            className='btn text-center'
          >
            Leseprobe
          </Link>
          <p className='text-bold'>Das Buch besteht aus {book.pages} Seiten</p>
          <p className='text-bold'>Erhältlich in den Ausgaben:</p>
          <table className='text-bold'>
            <tbody>
              <tr>
                <td>Taschenbuch</td>
                <td>{book.pricePocketBook} €</td>
              </tr>
              <tr>
                <td>​Gebundenes Buch </td>
                <td>{book.priceHardBack} €</td>
              </tr>
              <tr>
                <td>E-Book </td>
                <td>{book.priceEbook} €</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className='book-footer'>
        <div className='footer-links'>
          <a
            href={book.amazone}
            className='btn'
            target='_blank'
            rel='noopener noreferrer'
          >
            Sofort-Kaufen
          </a>
          <Link to={`/guestbook/${match.params.id}`} className='btn'>
            Gästebuch
          </Link>
          <Link to='/books' className='btn'>
            Zurück
          </Link>
        </div>
      </div>
    </Fragment>
  ) : (
    <Fragment>
      <h4>Loading...</h4>
    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const books = state.firestore.data.books;
  const book = books ? books[id] : null;
  return {
    book: book,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    {
      collection: 'books',
    },
  ])
)(Book);
