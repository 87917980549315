import {
  PAINTING_ERROR,
  UPLOAD_PAINTING,
  UPDATE_PAINTING,
  DELETE_PAINTING,
} from '../action/actionTypes';

const initState = {};

const paintingReducer = (state = initState, action) => {
  switch (action.type) {
    case UPLOAD_PAINTING:
      console.log('painting uploaded');
      return state;
    case UPDATE_PAINTING:
      console.log('painting updated');
      return state;
    case DELETE_PAINTING:
      console.log('painting delete');
      return state;
    case PAINTING_ERROR:
      console.log('create painting error', action.err);
      return state;
    default:
      return state;
  }
};

export default paintingReducer;
