import { setAlert } from './alertAction';
import {
  VIDEO_ERROR,
  UPLOAD_VIDEO,
  UPDATE_VIDEO,
  DELETE_VIDEO,
} from './actionTypes';

export const uploadVideo = (formData) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  try {
    await firestore.collection('videos').add({
      title: formData.title,
      description: formData.description,
      videoURL: formData.videoURL,
      createdAt: new Date(),
    });
    dispatch({ type: UPLOAD_VIDEO, formData });

    dispatch(setAlert('Video erfolgreich hochgeladen.', 'success'));
  } catch (err) {
    dispatch({
      type: VIDEO_ERROR,
      err,
    });
  }
};

export const updateVideo = (id, formData) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  try {
    await firestore.collection('videos').doc(id).update({
      title: formData.title,
      description: formData.description,
      videoURL: formData.videoURL,
      createdAt: formData.createdAt,
    });
    dispatch({ type: UPDATE_VIDEO, formData });

    dispatch(setAlert('Video erfolgreich editiert.', 'success'));
  } catch (err) {
    dispatch({
      type: VIDEO_ERROR,
      err,
    });
  }
};

export const deleteVideo = (id) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  if (window.confirm('Are you sure? This can NOT be undone!')) {
    const firestore = getFirestore();

    try {
      await firestore.collection('videos').doc(id).delete();

      dispatch({ type: DELETE_VIDEO });

      dispatch(setAlert('Video erfolgreich gelöscht.', 'success'));
    } catch (err) {
      dispatch({
        type: VIDEO_ERROR,
        err,
      });
    }
  }
};
