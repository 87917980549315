import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

import authReducer from './authReducer';
import alertReducer from './alertReducer';
import bookReducer from './bookReducer';
import contentReducer from './contentReducer';
import paintingReducer from './paintingReducer';
import drawingReducer from './drawingReducer';
import photoReducer from './photoReducer';
import videoReducer from './videoReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  alert: alertReducer,
  books: bookReducer,
  content: contentReducer,
  painting: paintingReducer,
  drawing: drawingReducer,
  photo: photoReducer,
  video: videoReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
});

export default rootReducer;
