import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

const DynamicContent = ({ contents, role }) => {
  return (
    <Fragment>
      {contents ? (
        contents &&
        contents
          .filter((item) => item.role.toLowerCase() === role)
          .map((filteredContent) => (
            <Fragment key={filteredContent.id}>
              <h2 className='lead'>{filteredContent.title}</h2>
              <pre className='dynamic-content'>{filteredContent.text}</pre>
            </Fragment>
          ))
      ) : (
        <h4>Loading...</h4>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  contents: state.firestore.ordered.contents,
});

export default compose(
  firestoreConnect(() => [
    { collection: 'contents', orderBy: ['createdAt', 'asc'] },
  ]),
  connect(mapStateToProps)
)(DynamicContent);
