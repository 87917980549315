import {
  DRAWING_ERROR,
  UPLOAD_DRAWING,
  UPDATE_DRAWING,
  DELETE_DRAWING,
} from '../action/actionTypes';

const initState = {};

const paintingReducer = (state = initState, action) => {
  switch (action.type) {
    case UPLOAD_DRAWING:
      console.log('drawing uploaded');
      return state;
    case UPDATE_DRAWING:
      console.log('drawing updated');
      return state;
    case DELETE_DRAWING:
      console.log('drawing delete');
      return state;
    case DRAWING_ERROR:
      console.log('create drawing error', action.err);
      return state;
    default:
      return state;
  }
};

export default paintingReducer;
