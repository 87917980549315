import React from 'react';
import { Link } from 'react-router-dom';

const BookItem = ({ book: { id, imageURL } }) => {
  return (
    <div className='book-container'>
      <div className='book-img text-center'>
        <p>
          <Link to={`/book/${id}`}>
            <img src={imageURL} alt='cover' className='cover-img' />
          </Link>
        </p>
        <p>
          <Link to={`/book/${id}`} className='btn'>
            Details
          </Link>
        </p>
      </div>
    </div>
  );
};

export default BookItem;
