import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const GalleryMain = () => {
  return (
    <div className='gallery-main'>
      <Helmet>
        <title>Künstler - Berlin | Andreas wolf von Guggenberger</title>
        <meta
          name='description'
          content='Nach einem Wasserschaden und Flucht aus seiner Wohnung, lebte er in einem Ferienheim in Brandenburg. In der Stille des Sees, begann er die Natur zu beobachten und plötzlich auf Papier zu kritzeln.
          - Licht und Schatten faszinierten ihn, das was sich in ihnen zeigte. 
          - Was formte die Oberflächen, die wir sehen?
          In diesen Fragen, verbanden sich all seine Interessen, die nun seinen Stift bewegten und in der Malerei und Photographie einen neuen Ausdruck fanden.'
        />
        <meta
          name='keywords'
          content='Landschaften, Portraits, Zeichnungen, Fotos, Berlin-Kreuzberg'
        />
      </Helmet>
      <Fragment>
        <h1 className='lead text-center'>Wie kam ich zur Kunst</h1>

        <p>
          Nach einem Wasserschaden und Flucht aus seiner Wohnung, lebte er in
          einem Ferienheim in Brandenburg. In der Stille des Sees, begann er die
          Natur zu beobachten und plötzlich auf Papier zu kritzeln.
        </p>
        <ul>
          <li>
            Licht und Schatten faszinierten ihn, das was sich in ihnen zeigte.{' '}
          </li>
          <li>Was formte die Oberflächen, die wir sehen?</li>
        </ul>
        <p>
          In diesen Fragen, verbanden sich all seine Interessen, die nun seinen
          Stift bewegten und in der Malerei und Photographie einen neuen
          Ausdruck fanden. Plötzlich erinnerte er sich, dass er 15 Jahre zuvor,
          Kurse an der Freien Kunstschule Berlin und bei den Gebrüdern Posin
          absolviert hatte. Weil er aber damals zu wenig Selbstvertrauen hatte
          und die Jungen um ihn herum wunderbar malten, legte er das Gelernte
          wieder beiseite. Er glaubte, dass es für ihn zu spät sei. Das sollte
          sich nun - 15 Jahre danach, ändern.
        </p>
        <div className='gallery-links'>
          <Link className='btn' to='/bilder'>
            Meine Gemälde
          </Link>
          <Link className='btn' to='/zeichnungen'>
            Meine Zeichnungen
          </Link>
          <Link className='btn' to='/fotos'>
            Meine Fotos
          </Link>
          <Link className='btn' to='/videos'>
            Meine Videos
          </Link>
        </div>
      </Fragment>
    </div>
  );
};

export default GalleryMain;
