import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

import { updatePhoto, deletePhoto } from '../../../redux/action/photoAction';

const EditPhoto = ({ updatePhoto, deletePhoto, photo, match, history }) => {
  const [formData, setFormData] = useState({
    title: '',
    format: '',
    imageName: '',
    imageURL: '',
    originalPrice: '',
    printPrice: '',
    createdAt: '',
  });

  useEffect(() => {
    if (photo) {
      setFormData({
        title: photo.title,
        format: photo.format,
        imageName: photo.imageName,
        imageURL: photo.imageURL,
        originalPrice: photo.originalPrice,
        printPrice: photo.printPrice,
        createdAt: photo.createdAt,
      });
    }
  }, [photo]);

  const { title, format, originalPrice, printPrice } = formData;

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    const id = match.params.id;

    updatePhoto(id, formData);
    history.push('/dashboard');
  };

  const handleDeletePhoto = (e) => {
    e.preventDefault();

    const id = match.params.id;
    const imageName = formData.imageName;

    deletePhoto(id, imageName);
    history.push('/dashboard');
  };

  return (
    <div className='dashboard-form'>
      <Link className='btn btn-light' to='/dashboard'>
        Zurück
      </Link>
      <h1 className='large text-center'>Foto bearbeiten</h1>
      <form className='form' onSubmit={onFormSubmit}>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Title'
            name='title'
            value={title}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Format'
            name='format'
            value={format}
            onChange={(e) => onChange(e)}
          ></input>
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Original Preis'
            name='originalPrice'
            value={originalPrice}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Preis für Druck'
            name='printPrice'
            value={printPrice}
            onChange={(e) => onChange(e)}
          />
        </div>
        <input type='submit' className='btn btn-primary' value='Senden' />
        <button className='btn btn-danger' onClick={handleDeletePhoto}>
          Löschen
        </button>
      </form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const photos = state.firestore.data.photos;
  const photo = photos ? photos[id] : null;
  return {
    photo: photo,
  };
};

export default compose(
  connect(mapStateToProps, { updatePhoto, deletePhoto }),
  firestoreConnect([
    {
      collection: 'photos',
    },
  ])
)(withRouter(EditPhoto));
