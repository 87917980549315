import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

const VideoList = ({ videos }) => {
  return videos ? (
    <div className='gallery-inner'>
      <h1 className='lead text-center'>Meine Videos</h1>
      <div className='gallery-container'>
        {videos.map((video) => (
          <Link to={`/video/${video.id}`} key={video.id}>
            <ReactPlayer
              light={true}
              url={video.videoURL}
              width='100%'
              height='100%'
            />
            <p>{video.title}</p>
          </Link>
        ))}
      </div>
    </div>
  ) : (
    <h4>Loading...</h4>
  );
};

const mapStateToProps = (state) => ({
  videos: state.firestore.ordered.videos,
});

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: 'videos', orderBy: ['createdAt', 'desc'] }])
)(VideoList);
