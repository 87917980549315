import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyANceBXVLZB987eEpUWF4gNHtFbA6U9BaQ',
  authDomain: 'seelenlichtraum.firebaseapp.com',
  projectId: 'seelenlichtraum',
  storageBucket: 'seelenlichtraum.appspot.com',
  messagingSenderId: '841975400129',
  appId: '1:841975400129:web:642183cda652bc224ef640',
  measurementId: 'G-50FF38XG58',
};

firebase.initializeApp(firebaseConfig);

export default firebase;
