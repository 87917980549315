import React from 'react';
import { Helmet } from 'react-helmet';

import ConsultingHead from './ConsultingHead';
import DynamicContent from '../../utils/DynamicContent';
import Anfrage from '../../utils/Anfrage';

const OnlineConsulting = () => {
  return (
    <div className='consulting'>
      <Helmet>
        <title>
          Beratung & Coaching Berlin Kreuzberg - Graphologische Gutachten |
          Andreas Wolf von Guggenberger
        </title>
        <meta
          name='description'
          content='Mit Hilfe eines graphologischen Gutachtens können wir ihre Stärken, Fähigkeiten und Grenzen durchleuchten. Sie werden für ihre Gefühle, inneren Dynamiken, Konflikte und ihr schöpferisches Wesen ein tieferes Verständnis entwickeln finden. Wichtig ist zu erforschen, welche Sicht- und Verhaltensweisen Sie in die Krise führten, für sich selbst Verständnis und Selbstvergebung zu finden. Das graphologische Gutachten soll ihnen helfen, sich ihrer Fähigkeiten neu bewusst zu werden und sie gestärkt ihren neuen Weg gehen lassen.'
        />
        <meta
          name='keywords'
          content='Beratung, Coaching, ART, Assoziatives Reiztraining, Berlin-Kreuzberg'
        />
      </Helmet>
      <ConsultingHead />
      <DynamicContent role='umorientierung' />
      <DynamicContent role='beratungs-preis' />
      <Anfrage />
    </div>
  );
};

export default OnlineConsulting;
