import React from 'react';
import { Helmet } from 'react-helmet';

import profileImage from '../../images/profile.png';
import signatur1 from '../../images/signatur1.png';
import signatur2 from '../../images/signatur2.png';
import DynamicContent from '../../utils/DynamicContent';
import Anfrage from '../../utils/Anfrage';

const Graphology = () => {
  return (
    <div className='graphology'>
      <Helmet>
        <title>
          Graphologie & Graphologische Gutachten - Berlin | Andreas Wolf von
          Guggenberger
        </title>
        <meta
          name='description'
          content='Mein Name ist Andreas, Wolf von Guggenberger. Ich bin Mitglied des Bundesverbandes geprüfter Graphologen und erstelle psychologische Profile, graphologische Gutachten - Persoenlichkeitsprofile aus Handschriften, für Personalberater, Berufsberater, Chefs und Suchende.'
        />
        <meta
          name='keywords'
          content='Graphologie, Graphologe, Gutachten, , Profile, Handschriften, Berlin'
        />
      </Helmet>
      <h1 className='text-center'>Graphologie</h1>
      <p>
        Mein Name ist Wolf von Guggenberger. Ich bin Mitglied des Verbandes...
        und erstelle psychologische Profile aus Handschriften.
      </p>
      <div className='graph-header'>
        <div className='head-img'>
          <img src={profileImage} alt='profile' />
        </div>
        <div className='head-txt'>
          <DynamicContent role='geschichte' />
        </div>
      </div>
      <h2 className='lead'>Für wen ist die Graphologie geeignet?</h2>
      <ul>
        <li>Unternehmer</li>
        <li>Personalberater</li>
        <li>Berufsberater</li>
        <li>
          Privatpersonen, die sich umorientieren wollen und sich selbst besser
          erkennen möchten.
        </li>
        <li>Paare in Beziehungskonflikten</li>
        <li>....</li>
      </ul>
      <DynamicContent role='beschreibt' />
      <DynamicContent role='geschieht' />
      <h2 className='lead'>Was kann man in einem Schriftbild erkennen?</h2>
      <ul>
        <li>Intro- /Extravertiertheit,</li>
        <li>Maske – wie sich der Mensch an seine Mitmenschen anpasst, </li>
        <li>Energiefluss, Spannungen, </li>
        <li>Kreativität,</li>
        <li>Konzentrationsfähigkeit</li>
        <li>langsam bedacht, vertieft/ angetrieben, impulsiv oberflächlich.</li>
        <li>
          Ordnungs-, Kontrollverhalten/ Impulsiv-emotionales, bewegtes Fühlen
          und Empfinden{' '}
        </li>
        <li>Anlagen der Persönlichkeit</li>
      </ul>
      <h2 className='lead'>Schriftbeispiele:</h2>
      <DynamicContent role='flach' />
      <div className='sample-img'>
        <img src={signatur1} alt='signatur' />
      </div>
      <DynamicContent role='groß' />
      <div className='sample-img'>
        <img src={signatur2} alt='signatur' />
      </div>
      <h2 className='lead'>Was ich für ein Gutachten brauche:</h2>
      <ul>
        <li>1 von Hand geschriebene Seite mit Unterschrift.</li>
      </ul>
      <DynamicContent role='graph-preis' />
      <Anfrage />
    </div>
  );
};

export default Graphology;
