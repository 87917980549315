import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { uploadVideo } from '../../../redux/action/videoAction';

const UploadVideo = ({ uploadVideo }) => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    videoURL: '',
  });

  const { title, description, videoURL } = formData;

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    uploadVideo(formData);
    setFormData({
      title: '',
      description: '',
      videoURL: '',
    });
  };

  return (
    <div className='dashboard-form'>
      <Link className='btn btn-light' to='/dashboard'>
        Zurück
      </Link>
      <h1 className='large text-center'>Neues Video hochladen</h1>
      <form className='form' onSubmit={onFormSubmit}>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Title'
            name='title'
            value={title}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Beschreibung'
            name='description'
            value={description}
            onChange={(e) => onChange(e)}
          ></input>
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Link'
            name='videoURL'
            value={videoURL}
            onChange={(e) => onChange(e)}
          />
        </div>
        <input type='submit' className='btn btn-primary' value='Senden' />
      </form>
    </div>
  );
};

export default connect(null, { uploadVideo })(UploadVideo);
