import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

const Paintings = ({ paintings }) => {
  return paintings ? (
    <div className='gallery-inner'>
      <Helmet>
        <title>Gemälde - Berlin | Andreas wolf von Guggenberger</title>
        <meta
          name='description'
          content='Nach einem Wasserschaden und Flucht aus seiner Wohnung, lebte er in einem Ferienheim in Brandenburg. In der Stille des Sees, begann er die Natur zu beobachten und plötzlich auf Papier zu kritzeln.
          - Licht und Schatten faszinierten ihn, das was sich in ihnen zeigte. 
          - Was formte die Oberflächen, die wir sehen?
          In diesen Fragen, verbanden sich all seine Interessen, die nun seinen Stift bewegten und in der Malerei und Photographie einen neuen Ausdruck fanden.'
        />
        <meta
          name='keywords'
          content='Landschaften, Portraits, Zeichnungen, Fotos, Berlin-Kreuzberg'
        />
      </Helmet>
      <h1 className='lead text-center'>Meine Gemälde</h1>
      <div className='gallery-container'>
        {paintings.map((painting) => (
          <Link to={`/bild/${painting.id}`} key={painting.id}>
            <img src={painting.imageURL} alt='Gemälde' />
            <p>{painting.title}</p>
          </Link>
        ))}
      </div>
    </div>
  ) : (
    <h4>Loading...</h4>
  );
};

const mapStateToProps = (state) => ({
  paintings: state.firestore.ordered.paintings,
});

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: 'paintings', orderBy: ['createdAt', 'asc'] }])
)(Paintings);
