import React from 'react';
import { Link } from 'react-router-dom';

import ConsultingHead from './ConsultingHead';
import DynamicContent from '../../utils/DynamicContent';

const OnlineConsulting = () => {
  return (
    <div className='consulting'>
      <ConsultingHead />
      <DynamicContent role='ausbildung' />
      <div className='consult-btn'>
        <Link to='/books' className='btn btn-success'>
          Zu meinen Büchern
        </Link>
      </div>
    </div>
  );
};

export default OnlineConsulting;
