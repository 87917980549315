import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { resetPassword } from '../../redux/action/authAction';

const ForgotPassword = ({ resetPassword, history }) => {
  const [formData, setFormData] = useState({
    email: '',
  });

  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();

    resetPassword(email);
    alert(
      'Eine Email wurde an deinen Account geschickt benutze den Link in deinem Postfach um dein Password zu ändern.'
    );
    history.push('/einloggen');
  };

  return (
    <div className='signin'>
      <Link to='/einloggen' className='btn'>
        Zurück
      </Link>
      <h1 className='large'>Forgot Password</h1>
      <p className='lead'>
        <i className='fas fa-user' /> Forgot your Password
      </p>
      <form className='form' onSubmit={(e) => onSubmit(e)}>
        <div className='form-group'>
          <input
            type='email'
            placeholder='Email Address'
            name='email'
            value={email}
            onChange={(e) => onChange(e)}
            required
          />
        </div>
        <input
          type='submit'
          className='btn btn-primary'
          value='Reset Password'
          disabled={!email}
        />
      </form>
    </div>
  );
};

export default connect(null, { resetPassword })(ForgotPassword);
