import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './scss/App.scss';

import Navbar from './components/Layout/Navbar';
import Home from './components/Home/Home';
import SignIn from './components/auth/SignIn';
import ForgotPassword from './components/auth/ForgotPassword';
import Impressum from './components/Layout/Impressum';
import Footer from './components/Layout/Footer';
import Datenschutz from './components/Layout/Datenschutz';
import Contact from './components/Contact/Contact';
import Alert from './components/Layout/Alert';

import Books from './components/Book/Books';
import Book from './components/Book/Book';
import Reading from './components/Book/Reading';
import GuestBook from './components/Book/GuestBook';
import Graphology from './components/Graphology/Graphology';
import GalleryMain from './components/Gallery/GalleryMain';
import Paintings from './components/Gallery/Paintings';
import Painting from './components/Gallery/Painting';
import Drawings from './components/Gallery/Drawings';
import Drawing from './components/Gallery/Drawing';
import Photos from './components/Gallery/Photos';
import Photo from './components/Gallery/Photo';
import Videos from './components/Gallery/Videos';
import Video from './components/Gallery/Video';
import OnlineConsulting from './components/Consulting/OnlineConsulting';
import Hypnose from './components/Consulting/Hypnose';
import AddictionConsulting from './components/Consulting/AddictionConsulting';
import AbuseConsulting from './components/Consulting/AbuseConsulting';
import AutogenTraining from './components/Consulting/AutogenTraining';
import Reiztraining from './components/Consulting/Reiztraining';
import GraphologyConsulting from './components/Consulting/GrapologyConsulting';
import MyEducation from './components/Consulting/MyEducation';
import Classic from './components/Massage/Classic';
import Segment from './components/Massage/Segment';
import Reflex from './components/Massage/Reflex';
import Chakra from './components/Massage/Chakra';

import Dashboard from './components/Dashboard/Dashboard';
import CreateBook from './components/Dashboard/BookSection/CreateBook';
import BookList from './components/Dashboard/BookSection/BookList';
import EditBook from './components/Dashboard/BookSection/EditBook';
import CreateContent from './components/Dashboard/ContentSection/CreateContent';
import ContentList from './components/Dashboard/ContentSection/ContentList';
import EditContent from './components/Dashboard/ContentSection/EditContent';
import UploadPainting from './components/Dashboard/PaintingSection/UploadPainting';
import EditPainting from './components/Dashboard/PaintingSection/EditPainting';
import PaintingList from './components/Dashboard/PaintingSection/PaintingList';
import UploadDrawing from './components/Dashboard/DrawingSection/UploadDrawing';
import EditDrawing from './components/Dashboard/DrawingSection/EditDrawing';
import DrawingList from './components/Dashboard/DrawingSection/DrawingList';
import UploadPhoto from './components/Dashboard/PhotoSection/UploadPhoto';
import PhotoList from './components/Dashboard/PhotoSection/PhotoList';
import EditPhoto from './components/Dashboard/PhotoSection/EditPhoto';
import UploadVideo from './components/Dashboard/VideoSection/UploadVideo';
import VideoList from './components/Dashboard/VideoSection/VideoList';
import EditVideo from './components/Dashboard/VideoSection/EditVideo';
import PrivateRoute from './components/Routes/PrivateRoute';
import NotFound from './components/Layout/NotFound';

function App() {
  return (
    <BrowserRouter>
      <div className='container'>
        <Navbar />
        <Alert />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/einloggen' component={SignIn} />
          <Route exact path='/forgot' component={ForgotPassword} />
          <Route exact path='/kontakt' component={Contact} />
          <Route exact path='/impressum' component={Impressum} />
          <Route exact path='/datenschutz' component={Datenschutz} />
          <Route exact path='/books' component={Books} />
          <Route exact path='/book/:id' component={Book} />
          <Route exact path='/leseprobe/:id' component={Reading} />
          <Route exact path='/guestbook/:id' component={GuestBook} />
          <Route exact path='/graphology' component={Graphology} />
          <Route exact path='/galerie' component={GalleryMain} />
          <Route exact path='/online-consulting' component={OnlineConsulting} />
          <Route exact path='/hypnose' component={Hypnose} />
          <Route exact path='/suchtberatung' component={AddictionConsulting} />
          <Route
            exact
            path='/missbrauchsberatung'
            component={AbuseConsulting}
          />
          <Route exact path='/autogenes-training' component={AutogenTraining} />
          <Route exact path='/reiztraining' component={Reiztraining} />
          <Route
            exact
            path='/graphology-consulting'
            component={GraphologyConsulting}
          />
          <Route exact path='/ausbildung' component={MyEducation} />
          <Route exact path='/klassische-massage' component={Classic} />
          <Route exact path='/segment-massage' component={Segment} />
          <Route exact path='/reflex-massage' component={Reflex} />
          <Route exact path='/chakra-arbeit' component={Chakra} />
          <Route exact path='/bilder' component={Paintings} />
          <Route exact path='/bild/:id' component={Painting} />
          <Route exact path='/zeichnungen' component={Drawings} />
          <Route exact path='/zeichnung/:id' component={Drawing} />
          <Route exact path='/fotos' component={Photos} />
          <Route exact path='/foto/:id' component={Photo} />
          <Route exact path='/videos' component={Videos} />
          <Route exact path='/video/:id' component={Video} />
          <PrivateRoute exact path='/dashboard' component={Dashboard} />
          <PrivateRoute exact path='/create-book' component={CreateBook} />
          <PrivateRoute exact path='/book-list' component={BookList} />
          <PrivateRoute exact path='/edit-book/:id' component={EditBook} />
          <PrivateRoute
            exact
            path='/create-content'
            component={CreateContent}
          />
          <PrivateRoute exact path='/content-list' component={ContentList} />
          <PrivateRoute
            exact
            path='/edit-content/:id'
            component={EditContent}
          />
          <PrivateRoute
            exact
            path='/upload-painting'
            component={UploadPainting}
          />
          <PrivateRoute
            exact
            path='/edit-painting/:id'
            component={EditPainting}
          />
          <PrivateRoute exact path='/painting-list' component={PaintingList} />
          <PrivateRoute
            exact
            path='/upload-drawing'
            component={UploadDrawing}
          />
          <PrivateRoute
            exact
            path='/edit-drawing/:id'
            component={EditDrawing}
          />
          <PrivateRoute exact path='/drawing-list' component={DrawingList} />
          <PrivateRoute exact path='/upload-photo' component={UploadPhoto} />
          <PrivateRoute exact path='/photo-list' component={PhotoList} />
          <PrivateRoute exact path='/edit-photo/:id' component={EditPhoto} />
          <PrivateRoute exact path='/upload-video' component={UploadVideo} />
          <PrivateRoute exact path='/video-list' component={VideoList} />
          <PrivateRoute exact path='/edit-video/:id' component={EditVideo} />
          <Route component={NotFound} />
        </Switch>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
