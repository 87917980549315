import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

const BookList = ({ books }) => {
  return (
    <div className='gallery-inner'>
      <Link className='btn btn-light' to='/dashboard'>
        Zurück
      </Link>
      <h1 className='lead text-center'>Wähle ein Buch zum editieren aus</h1>
      <div className='gallery-container '>
        <div className='dashboard-book-list'>
          {books &&
            books.map((book) => (
              <Link to={`/edit-book/${book.id}`} key={book.id}>
                <img src={book.imageURL} alt='Cover' />
                <p>{book.title}</p>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  books: state.firestore.ordered.books,
});

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: 'books', orderBy: ['createdAt', 'asc'] }])
)(BookList);
