import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOut } from '../../redux/action/authAction';

const SignedInLinks = ({ signOut }) => {
  const toogleNavbar = () => {
    let nav = document.getElementById('customNavbar');
    if (nav.className === 'navbar') {
      nav.className += ' responsive';
    } else {
      nav.className = 'navbar';
    }
  };
  return (
    <Fragment>
      <Link to='/' onClick={toogleNavbar} className='nav-link'>
        Home
      </Link>
      <Link to='/books' onClick={toogleNavbar} className='nav-link'>
        Bücher
      </Link>
      <div className='nav-dropdown nav-link'>
        <button className='nav-dropbtn'>
          Galerie <i className='fa fa-caret-down'></i>
        </button>
        <div className='nav-dropdown-content'>
          <Link to='/galerie' onClick={toogleNavbar}>
            Wie kam ich zur Kunst
          </Link>
          <Link to='/bilder' onClick={toogleNavbar}>
            Gemälde
          </Link>
          <Link to='/zeichnungen' onClick={toogleNavbar}>
            Zeichnungen
          </Link>
          <Link to='/fotos' onClick={toogleNavbar}>
            Fotos
          </Link>
          <Link to='/videos' onClick={toogleNavbar}>
            Videos
          </Link>
        </div>
      </div>
      <div className='nav-dropdown nav-link'>
        <button className='nav-dropbtn'>
          Massage <i className='fa fa-caret-down'></i>
        </button>
        <div className='nav-dropdown-content'>
          <Link to='/klassische-massage' onClick={toogleNavbar}>
            Klassische Behandlung
          </Link>
          <Link to='/segment-massage' onClick={toogleNavbar}>
            Segmentmassage
          </Link>
          <Link to='/reflex-massage' onClick={toogleNavbar}>
            Fussreflex mit oder ohne Energiebehandlung
          </Link>
          <Link to='/chakra-arbeit' onClick={toogleNavbar}>
            Energiebehandlung
          </Link>
          <Link to='/chakra-arbeit' onClick={toogleNavbar}>
            Chakrenarbeit, Ausgleichung und Reinigung
          </Link>
        </div>
      </div>
      <div className='nav-dropdown nav-link'>
        <button className='nav-dropbtn'>
          Beratungen & Coaching <i className='fa fa-caret-down'></i>
        </button>
        <div className='nav-dropdown-content'>
          <Link to='/online-consulting' onClick={toogleNavbar}>
            Onlineberatung
          </Link>
          <Link to='/graphology-consulting' onClick={toogleNavbar}>
            Graphologische Beratung
          </Link>

          <Link to='/hypnose' onClick={toogleNavbar}>
            Hypnose, NLP, EMDR
          </Link>
          <Link to='/autogenes-training' onClick={toogleNavbar}>
            Autogenes Training & Entspannungstechniken
          </Link>
          <Link to='/suchtberatung' onClick={toogleNavbar}>
            Suchtberatung
          </Link>
          <Link to='/missbrauchsberatung' onClick={toogleNavbar}>
            Missbrauchsfragen
          </Link>

          <Link to='/reiztraining' onClick={toogleNavbar}>
            AST - Assoziatives Reiztraining
          </Link>

          <Link to='/ausbildung' onClick={toogleNavbar}>
            Meine Ausbildungen
          </Link>
        </div>
      </div>
      <div className='nav-dropdown nav-link'>
        <button className='nav-dropbtn'>
          Graphologie <i className='fa fa-caret-down'></i>
        </button>
        <div className='nav-dropdown-content'>
          <Link to='/graphology' onClick={toogleNavbar}>
            Graphologische Gutachten
          </Link>
          <Link to='/graphology-consulting' onClick={toogleNavbar}>
            Beratung anhand des Gutachtens
          </Link>
        </div>
      </div>
      <Link to='/dashboard' onClick={toogleNavbar} className='nav-link'>
        Dashboard
      </Link>
      <a onClick={signOut} href='#!' className='nav-link'>
        Logout
      </a>
    </Fragment>
  );
};

export default connect(null, { signOut })(SignedInLinks);
