import React from 'react';
import { Helmet } from 'react-helmet';

import profileImage from '../../images/profile.jpg';
import DynamicContent from '../../utils/DynamicContent';
import Anfrage from '../../utils/Anfrage';

const Classic = () => {
  return (
    <div className='consulting'>
      <Helmet>
        <title>
          Fussreflexmassage Massage Berlin Kreuzberg - mit oder ohne
          Energiearbeit | Andreas Wolf von Guggenberger
        </title>
        <meta
          name='description'
          content='An ihren Füßen arbeite ich ohne Öl mit rhythmischen Daumenbewegungen auf ihren Energiepunkten, die ich aktiviere oder beruhige. Die Fußreflexmassage wirkt über Energiebahnen auf die Organe, um ihre Selbstheilungskräfte zu aktivieren.'
        />
        <meta
          name='keywords'
          content='Massage, Fussreflex Massage, Energiearbeit, Berlin-Kreuzberg, Engel, Geistheilung, Energiekörper'
        />
      </Helmet>
      <div className='consulting-head'>
        <h1 className='text-center'>Fussreflexmassage</h1>
        <div className='consulting-header'>
          <div className='head-img'>
            <img src={profileImage} alt='profile' />
          </div>
          <div className='head-text'>
            <p>
              Ich biete Ihnen entspannende, beruhigende und Sie wieder
              zentrierende Massagen an:
            </p>
            <ul>
              <li>
                KLASSISCHE MASSAGE wirkt über die Muskeln tief entspannend.
              </li>
              <li>
                FUSSREFLEX- UND SEGMENTMASSAGE, die über Reflexpunkte und
                Energiebahnen auf die Organe wirken.
              </li>
              <li>
                ENERGIE- UND CHAKRENARBEIT, die ihre Selbstheilungskräfte
                stärken, ihre Meridiane reinigen und öffnen
              </li>
              <li>Eine Kombination dieser Möglichkeiten</li>
            </ul>
          </div>
        </div>
      </div>
      <DynamicContent role='reflex' />
      <DynamicContent role='massage-preis' />
      <Anfrage />
    </div>
  );
};

export default Classic;
