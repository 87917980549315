import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import ConsultingHead from './ConsultingHead';
import DynamicContent from '../../utils/DynamicContent';
import Anfrage from '../../utils/Anfrage';

const OnlineConsulting = () => {
  return (
    <div className='consulting'>
      <Helmet>
        <title>
          Beratung & Coaching Berlin Kreuzberg - Onlineberatung | Andreas Wolf
          von Guggenberger
        </title>
        <meta
          name='description'
          content='Ich höre Ihnen zu und versuche 
          - ihr Problem zu verstehen, 
          - das was sie ausdrücken wollen.
          
          Wenn wir das Problem benannt haben, werden wir es mit einem Symbol - einer Blume, verbinden, mit der wir arbeiten. Dazu benutzen wir ihre Vorstellungskraft. Mit Hilfe der Hypnose machen wir ihre Vorstellung lebendiger, um zu erkennen, was sich in ihr verbirgt. '
        />
        <meta
          name='keywords'
          content='Beratung, Coaching, Hypnose, Gutachten, Berlin-Kreuzberg'
        />
      </Helmet>
      <ConsultingHead />
      <h2 className='lead online-heading'>ONLINEBERATUNG</h2>
      <DynamicContent role='arbeit' />
      <h2 className='lead'>WAS BEI HYPNOSE UND TRANCE GANZ WICHTIG IST:</h2>
      <ul>
        <li>
          In der leichten Trance bleiben Sie ständig wach und können sich
          genaustens an alles erinnern.{' '}
        </li>
        <li>
          Sie bleiben wach, um zu lernen, wie sie selbst mit einem Problem
          umgehen können.
        </li>
        <li>
          Sie lernen ihre Phantasie und ihr Vorstellungsvermögen aktiv für
          Problemlösungen einzusetzen.
        </li>
      </ul>
      <p className='hypnose-parag'>
        Hintergründe dazu können sie lesen bei{' '}
        <Link to='/hypnose'>
          <span className='text-bold'> Hypnose, NLP, EMDR</span>
        </Link>
        .
      </p>
      <h2 className='lead'>DIE PRINZIPIEN MEINER ARBEIT</h2>
      <p className='hypnose-parag'>
        Probleme sind in ihren Erinnerungen gespeichert, die aus Symbolen und
        Filmen bestehen. Ich gehe davon aus, dass nicht alles erinnert werden
        muss. Wir heilen keinen Beinbruch, indem wir jemanden nochmal die Treppe
        hinunter stürzen lassen. Aus diesem Grund verpacke ich die Erinnerung
        /en gerne in ein Symbol, indem das Problem Ausdruck findet. Angenommen
        ihr Problem ist in einer Rose verpackt.
      </p>
      <ul>
        <li>Eine Änderung der Farbe</li>
        <li>Eine Änderung der Form</li>
        <li>verändert sofort das, wofür es steht.</li>
        <li>Es ändert das Problem.</li>
        <li>
          Sie werden darin neue, oft ausgeblendete Sichtweisen wieder erkennen,
        </li>
        <li>dadurch auch neue Kontexte drum herum erkennen.</li>
      </ul>
      <p className='hypnose-parag'>
        Solche Prozesse können sehr heilbar sein, weil sie Belastendes positiv
        und aktiv verwandeln lernen. Sie integrieren verdrängte Potentiale und
        Kräfte, die ihnen neu zu Verfügung stehen. Damit stehen Ihnen neue
        Möglichkeiten und Wege zur Verfügung, die sich Ihnen eröffnen werden.
      </p>
      <p className='hypnose-parag'>
        Hintergründe dazu können sie lesen bei{' '}
        <Link to='/hypnose'>
          <span className='text-bold'> Hypnose, NLP, EMDR</span>
        </Link>
        .
      </p>
      <DynamicContent role='wunsch' />
      <DynamicContent role='beratungs-preis' />
      <Anfrage />
    </div>
  );
};

export default OnlineConsulting;
