import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import profileImage from '../../images/profile.jpg';
import DynamicContent from '../../utils/DynamicContent';
import Anfrage from '../../utils/Anfrage';

const Classic = () => {
  return (
    <div className='consulting'>
      <Helmet>
        <title>
          Energiearbeit & Chakrenarbeit Berlin Kreuzberg - mit Beratung
          kombinierbar | Andreas Wolf von Guggenberger
        </title>
        <meta
          name='description'
          content='Ich arbeite mit den Händen an ihren Chakren, den Energiepunkten der Wirbelsäule und an ihren Füßen. Diese tiefgehende Arbeit, kann ich mit den Massagen und der Beratung kombinieren.'
        />
        <meta
          name='keywords'
          content='Massage, Chakrenarbeit, Energiearbeit, Beratung, Berlin-Kreuzberg, Engel, Geistheilung, Energiekörper'
        />
      </Helmet>
      <div className='consulting-head'>
        <h1 className='text-center'>Energie- & Chakrenarbeit</h1>
        <div className='consulting-header'>
          <div className='head-img'>
            <img src={profileImage} alt='profile' />
          </div>
          <div className='head-text'>
            <p>
              Ich biete Ihnen entspannende, beruhigende und Sie wieder
              zentrierende Massagen an:
            </p>
            <ul>
              <li>
                KLASSISCHE MASSAGE wirkt über die Muskeln tief entspannend.
              </li>
              <li>
                FUSSREFLEX- UND SEGMENTMASSAGE, die über Reflexpunkte und
                Energiebahnen auf die Organe wirken.
              </li>
              <li>
                ENERGIE- UND CHAKRENARBEIT, die ihre Selbstheilungskräfte
                stärken, ihre Meridiane reinigen und öffnen
              </li>
              <li>Eine Kombination dieser Möglichkeiten</li>
            </ul>
          </div>
        </div>
      </div>
      <DynamicContent role='chakra' />
      <p className='hypnose-parag'>
        Wie ich dazu kam, können sie unter dem Link:
        <Link to='/hypnose'>
          <span className='text-bold'> Hypnose, NLP, EMDR</span>
        </Link>
        , am Ende der Hypnose und bei der EMDR Beschreibung nachlesen.
      </p>
      <DynamicContent role='fernheilung' />
      <p className='hypnose-parag'>
        Dazu können unter dem Link:
        <Link to='/hypnose'>
          <span className='text-bold'> Hypnose, NLP, EMDR</span>
        </Link>
        , am Ende der Hypnose, Informationen zur Energiebehandlung lesen.
      </p>
      <DynamicContent role='massage-preis' />
      <Anfrage />
    </div>
  );
};

export default Classic;
