import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

const Photos = ({ photos }) => {
  return photos ? (
    <div className='gallery-inner'>
      <Helmet>
        <title>Fotos - Berlin | Andreas wolf von Guggenberger</title>
        <meta
          name='description'
          content='Auf diese Seite finden sie Fotos über Obdachlosigkeit, Fotos von Marroko, Fotos von Indien'
        />
        <meta
          name='keywords'
          content='Bleistiftzeichnungen, Gemälde,  Zeichnungen, Fotos, Berlin-Kreuzberg'
        />
      </Helmet>
      <h1 className='lead text-center'>Meine Fotos</h1>
      <div className='gallery-container'>
        {photos.map((photo) => (
          <Link to={`/foto/${photo.id}`} key={photo.id}>
            <img src={photo.imageURL} alt='Foto' />
            <p>{photo.title}</p>
          </Link>
        ))}
      </div>
    </div>
  ) : (
    <h4>Loading...</h4>
  );
};

const mapStateToProps = (state) => ({
  photos: state.firestore.ordered.photos,
});

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: 'photos', orderBy: ['createdAt', 'asc'] }])
)(Photos);
