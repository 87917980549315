import {
  CONTENT_ERROR,
  ADD_CONTENT,
  UPDATE_CONTENT,
  DELETE_CONTENT,
} from '../action/actionTypes';

const initState = {};

const bookReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_CONTENT:
      console.log('created content');
      return state;
    case UPDATE_CONTENT:
      console.log('content updated');
      return state;
    case DELETE_CONTENT:
      console.log('content delete');
      return state;
    case CONTENT_ERROR:
      console.log('create content error', action.err);
      return state;
    default:
      return state;
  }
};

export default bookReducer;
