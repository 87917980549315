import React from 'react';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  return (
    <div className='dashboard'>
      <h1 className='large text-center'>Admin Dashboard</h1>
      <div className='dashboard-inner'>
        <div className='section'>
          <h3 className='lead'>Bücher</h3>
          <Link to='/create-book' className='btn btn-success'>
            Buch erstellen
          </Link>
          <Link to='/book-list' className='btn btn-success'>
            Buch editiren
          </Link>
        </div>
        <div className='section'>
          <h3 className='lead'>Text</h3>
          <Link to='/create-content' className='btn btn-warning text-center'>
            Text erstellen
          </Link>
          <Link to='/content-list' className='btn btn-warning'>
            Text editiren
          </Link>
        </div>
        <div className='section'>
          <h3 className='lead'>Bilder</h3>
          <Link to='/upload-painting' className='btn btn-primary'>
            Bild hochladen
          </Link>
          <Link to='/painting-list' className='btn btn-primary'>
            Bild editiren
          </Link>
        </div>
        <div className='section'>
          <h3 className='lead'>Zeichnungen</h3>
          <Link to='/upload-drawing' className='btn btn-primary'>
            Zeichnung hochladen
          </Link>
          <Link to='/drawing-list' className='btn btn-primary'>
            Zeichnung editiren
          </Link>
        </div>
        <div className='section'>
          <h3 className='lead'>Foto</h3>
          <Link to='/upload-photo' className='btn btn-primary'>
            Foto hochladen
          </Link>
          <Link to='/photo-list' className='btn btn-primary'>
            Foto editiren
          </Link>
        </div>
        <div className='section'>
          <h3 className='lead'>Video</h3>
          <Link to='/upload-video' className='btn btn-primary'>
            Video hochladen
          </Link>
          <Link to='/video-list' className='btn btn-primary'>
            Video editiren
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
