import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

import {
  updateDrawing,
  deleteDrawing,
} from '../../../redux/action/drawingAction';

const EditDrawing = ({
  updateDrawing,
  deleteDrawing,
  drawing,
  match,
  history,
}) => {
  const [formData, setFormData] = useState({
    title: '',
    material: '',
    format: '',
    imageName: '',
    imageURL: '',
    originalPrice: '',
    createdAt: '',
  });

  useEffect(() => {
    if (drawing) {
      setFormData({
        title: drawing.title,
        material: drawing.material,
        format: drawing.format,
        imageName: drawing.imageName,
        imageURL: drawing.imageURL,
        originalPrice: drawing.originalPrice,
        createdAt: drawing.createdAt,
      });
    }
  }, [drawing]);

  const { title, material, format, originalPrice } = formData;

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    const id = match.params.id;

    updateDrawing(id, formData);
    history.push('/dashboard');
  };

  const handleDeletedrawing = (e) => {
    e.preventDefault();

    const id = match.params.id;
    const imageName = formData.imageName;

    deleteDrawing(id, imageName);
    history.push('/dashboard');
  };

  return (
    <div className='dashboard-form'>
      <Link className='btn btn-light' to='/dashboard'>
        Zurück
      </Link>
      <h1 className='large text-center'>Zeichnung bearbeiten</h1>
      <form className='form' onSubmit={onFormSubmit}>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Title'
            name='title'
            value={title}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Material'
            name='material'
            value={material}
            onChange={(e) => onChange(e)}
          ></input>
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Format'
            name='format'
            value={format}
            onChange={(e) => onChange(e)}
          ></input>
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Original Preis'
            name='originalPrice'
            value={originalPrice}
            onChange={(e) => onChange(e)}
          />
        </div>
        <input type='submit' className='btn btn-primary' value='Senden' />
        <button className='btn btn-danger my-1' onClick={handleDeletedrawing}>
          Löschen
        </button>
      </form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const drawings = state.firestore.data.drawings;
  const drawing = drawings ? drawings[id] : null;
  return {
    drawing: drawing,
  };
};

export default compose(
  connect(mapStateToProps, { updateDrawing, deleteDrawing }),
  firestoreConnect([
    {
      collection: 'drawings',
    },
  ])
)(withRouter(EditDrawing));
