import React from 'react';
import { Helmet } from 'react-helmet';

import profileImage from '../../images/profile.jpg';
import DynamicContent from '../../utils/DynamicContent';
import Anfrage from '../../utils/Anfrage';

const Classic = () => {
  return (
    <div className='consulting'>
      <Helmet>
        <title>
          Segmentmassage Berlin Kreuzberg - mit oder ohne Energiearbeit |
          Andreas Wolf von Guggenberger
        </title>
        <meta
          name='description'
          content='Mit ihr arbeite ich an ihrer Wirbelsäule. Reizend aktiviere ich Nerven- und Energiebahnen, um so Impulse in ihre Organe zu schicken und Ausgleiche zu schaffen. Die Wirkung ist ähnlich der Fußreflexmassage. Beide Massagearten können zielgerichtete und gesamtheitlich medizinische, vom Arzt angeordnete Therapien unterstützen. Beide können sehr Wirkungsvoll mit meiner Energiearbeit kombiniert werden'
        />
        <meta
          name='keywords'
          content='Massage, Segmentmassage, Energiearbeit, Berlin-Kreuzberg'
        />
      </Helmet>
      <div className='consulting-head'>
        <h1 className='text-center'>Segmentmassage</h1>
        <div className='consulting-header'>
          <div className='head-img'>
            <img src={profileImage} alt='profile' />
          </div>
          <div className='head-text'>
            <p>
              Ich biete Ihnen entspannende, beruhigende und Sie wieder
              zentrierende Massagen an:
            </p>
            <ul>
              <li>
                KLASSISCHE MASSAGE wirkt über die Muskeln tief entspannend.
              </li>
              <li>
                FUSSREFLEX- UND SEGMENTMASSAGE, die über Reflexpunkte und
                Energiebahnen auf die Organe wirken.
              </li>
              <li>
                ENERGIE- UND CHAKRENARBEIT, die ihre Selbstheilungskräfte
                stärken, ihre Meridiane reinigen und öffnen
              </li>
              <li>Eine Kombination dieser Möglichkeiten</li>
            </ul>
          </div>
        </div>
      </div>
      <DynamicContent role='segment' />
      <DynamicContent role='massage-preis' />
      <Anfrage />
    </div>
  );
};

export default Classic;
