import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

import { updateVideo, deleteVideo } from '../../../redux/action/videoAction';

const EditVideo = ({ updateVideo, deleteVideo, video, match, history }) => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    videoURL: '',
    createdAt: '',
  });

  useEffect(() => {
    if (video) {
      setFormData({
        title: video.title,
        description: video.description,
        videoURL: video.videoURL,
        createdAt: video.createdAt,
      });
    }
  }, [video]);

  const { title, description, videoURL } = formData;

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    const id = match.params.id;

    updateVideo(id, formData);
    history.push('/dashboard');
  };

  const handleDeleteVideo = (e) => {
    e.preventDefault();

    const id = match.params.id;

    deleteVideo(id);
    history.push('/dashboard');
  };

  return (
    <div className='dashboard-form'>
      <Link className='btn btn-light' to='/dashboard'>
        Zurück
      </Link>
      <h1 className='large text-center'>Video bearbeiten</h1>
      <form className='form' onSubmit={onFormSubmit}>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Title'
            name='title'
            value={title}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Bechreibung'
            name='description'
            value={description}
            onChange={(e) => onChange(e)}
          ></input>
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Link'
            name='videoURL'
            value={videoURL}
            onChange={(e) => onChange(e)}
          ></input>
        </div>
        <input type='submit' className='btn btn-primary' value='Senden' />
        <button className='btn btn-danger' onClick={handleDeleteVideo}>
          Löschen
        </button>
      </form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const videos = state.firestore.data.videos;
  const video = videos ? videos[id] : null;
  return {
    video: video,
  };
};

export default compose(
  connect(mapStateToProps, { updateVideo, deleteVideo }),
  firestoreConnect([
    {
      collection: 'videos',
    },
  ])
)(withRouter(EditVideo));
