import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='footer'>
      <p className='text-center text-bold'>
        <Link to='/impressum'>Impressum |</Link>
        <Link to='/datenschutz'> Datenschutz |</Link> Copyright &copy;
        SeelenLichtRraum {new Date().getFullYear()}
      </p>
    </div>
  );
};

export default Footer;
