import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

const PhotoLinks = ({ photos }) => {
  return (
    <div className='gallery-inner'>
      <Link className='btn btn-light' to='/dashboard'>
        Zurück
      </Link>
      <h1 className='lead text-center'>Wähle ein Foto zum editieren aus</h1>
      <div className='gallery-container'>
        {photos &&
          photos.map((photo) => (
            <Link to={`/edit-photo/${photo.id}`} key={photo.id}>
              <img src={photo.imageURL} alt='Foto' />
              <p>{photo.title}</p>
            </Link>
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  photos: state.firestore.ordered.photos,
});

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: 'photos', orderBy: ['createdAt', 'asc'] }])
)(PhotoLinks);
