import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player/lazy';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

const VideoList = ({ videos }) => {
  return (
    <div className='gallery-inner'>
      <Link className='btn btn-light' to='/dashboard'>
        Zurück
      </Link>
      <h1 className='lead text-center'>Wähle ein Video zum editieren aus</h1>
      <div className='gallery-container'>
        {videos &&
          videos.map((video) => (
            <Link to={`/edit-video/${video.id}`} key={video.id}>
              <ReactPlayer url={video.videoURL} width='100%' height='100%' />
              <p>{video.title}</p>
            </Link>
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  videos: state.firestore.ordered.videos,
});

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: 'videos', orderBy: ['createdAt', 'asc'] }])
)(VideoList);
