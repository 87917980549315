import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

import { addGuestEntry } from '../../redux/action/bookAction';
import GuestBookItem from './GuestBookItem';

const GuestBook = ({ addGuestEntry, book, match }) => {
  const [formData, setFormData] = useState({
    author: '',
    email: '',
    text: '',
  });

  const { author, email, text } = formData;

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    const id = match.params.id;

    addGuestEntry(id, formData);
    setFormData({
      author: '',
      email: '',
      text: '',
    });
  };

  return book ? (
    <div className='guestbook'>
      <div className='guestbook-header'>
        <Link to='/books' className='btn'>
          Zurück
        </Link>
        <h1 className='large text-center'>Gästebuch</h1>
      </div>

      <div className='guestbook-details'>
        <div className='guestbook-img'>
          <img src={book.imageURL} alt='cover' />
        </div>
        <div className='guestbook-content'>
          <h1>{book.title}</h1>
          <form className='form' onSubmit={onFormSubmit}>
            <div className='form-group'>
              <input
                type='text'
                placeholder='Name'
                name='author'
                value={author}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className='form-group'>
              <input
                type='email'
                placeholder='Email *optional'
                name='email'
                value={email}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className='form-group'>
              <textarea
                type='text'
                placeholder='Schreib wie dir das Buch gefallen hat.'
                name='text'
                value={text}
                onChange={(e) => onChange(e)}
                rows='5'
                cols='1'
              ></textarea>
              <button type='submit' className='btn btn-primary'>
                Senden
              </button>
            </div>
          </form>
        </div>
      </div>
      <hr />
      <div className='guestbook-footer'>
        {book.guestbook
          ? book.guestbook.map((entry) => (
              <GuestBookItem key={entry.id} entry={entry} />
            ))
          : null}
      </div>
    </div>
  ) : (
    <h5>Loading...</h5>
  );
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const books = state.firestore.data.books;
  const book = books ? books[id] : null;
  return {
    book: book,
  };
};

export default compose(
  connect(mapStateToProps, { addGuestEntry }),
  firestoreConnect([
    {
      collection: 'books',
    },
  ])
)(GuestBook);
