import React from 'react';
import Moment from 'react-moment';

const GuestBookItem = ({ entry: { author, text, createdAt } }) => {
  return (
    <div className='guest-post'>
      <p className='post-author'>Erstellt von {author}</p>
      <p className='post-content my'>{text}</p>
      <p className='post-date'>
        Erstellt am <Moment format='YYYY/MM/DD'>{createdAt}</Moment>
      </p>
    </div>
  );
};

export default GuestBookItem;
