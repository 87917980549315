import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

import {
  updateContent,
  deleteContent,
} from '../../../redux/action/contentAction';

const EditContent = ({
  updateContent,
  deleteContent,
  content,
  history,
  match,
}) => {
  const [formData, setFormData] = useState({
    category: '',
    role: '',
    title: '',
    text: '',
    createdAt: '',
  });

  useEffect(() => {
    if (content) {
      setFormData({
        category: content.category,
        role: content.role,
        title: content.title,
        text: content.text,
        createdAt: content.createdAt,
      });
    }
  }, [content]);

  const { category, role, title, text } = formData;

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    const id = match.params.id;

    updateContent(id, formData);

    history.push('/dashboard');
  };

  const handleDeleteContent = (e) => {
    e.preventDefault();

    const id = match.params.id;

    deleteContent(id);

    history.push('/dashboard');
  };

  return (
    <div className='dashboard-form'>
      <Link className='btn btn-light' to='/dashboard'>
        Zurück
      </Link>
      <h1 className='large text-center'>Inhalt bearbeiten</h1>
      <form className='form' onSubmit={onFormSubmit}>
        <div className='form-groupe'>
          <input
            type='text'
            placeholder='Kategorie'
            name='category'
            value={category}
            onChange={(e) => onChange(e)}
            readOnly
          />
        </div>
        <div className='form-groupe'>
          <input
            type='text'
            placeholder='Rolle'
            name='role'
            value={role}
            onChange={(e) => onChange(e)}
            readOnly
          />
        </div>
        <div className='form-groupe'>
          <input
            type='text'
            placeholder='Title'
            name='title'
            value={title}
            onChange={(e) => onChange(e)}
          />
          <div className='form-group'>
            <textarea
              type='text'
              placeholder='Inhalt'
              name='text'
              value={text}
              onChange={(e) => onChange(e)}
              rows='10'
              cols='1'
            ></textarea>
          </div>
        </div>
        <input type='submit' className='btn btn-primary' value='Senden' />
        <button className='btn btn-danger my-1' onClick={handleDeleteContent}>
          Löschen
        </button>
      </form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const contents = state.firestore.data.contents;
  const content = contents ? contents[id] : null;
  return {
    content: content,
  };
};

export default compose(
  connect(mapStateToProps, { updateContent, deleteContent }),
  firestoreConnect([
    {
      collection: 'contents',
    },
  ])
)(withRouter(EditContent));
