import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

const ContentList = ({ contents }) => {
  return (
    <div className='gallery-inner'>
      <Link className='btn btn-light' to='/dashboard'>
        Zurück
      </Link>
      <h1 className='lead text-center'>Wähle ein Inhalt zum editieren aus</h1>
      <div className='gallery-container'>
        {contents &&
          contents.map((content) => (
            <Link to={`/edit-content/${content.id}`} key={content.id}>
              <h3>Kategorie: {content.category}</h3>
              <p>{content.title}</p>
            </Link>
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  contents: state.firestore.ordered.contents,
});

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: 'contents', orderBy: ['createdAt', 'asc'] }])
)(ContentList);
