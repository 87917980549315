import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { addNewBook } from '../../../redux/action/bookAction';

const CreateBook = ({ addNewBook }) => {
  const [image, setImage] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    reading: '',
    pages: '',
    amazone: '',
    guestbook: [],
    pricePocketBook: '',
    priceHardBack: '',
    priceEbook: '',
  });

  const {
    title,
    description,
    reading,
    pages,
    amazone,
    pricePocketBook,
    priceHardBack,
    priceEbook,
  } = formData;

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const hanldeUploadChange = (e) => {
    setImage(e.target.files[0]);
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();

    addNewBook(formData, image);

    setImage(null);
    setFormData({
      title: '',
      description: '',
      reading: '',
      pages: '',
      amazone: '',
      guestbook: [],
      pricePocketBook: '',
      priceHardBack: '',
      priceEbook: '',
    });
  };

  return (
    <div className='dashboard-form'>
      <Link className='btn btn-light' to='/dashboard'>
        Zurück
      </Link>
      <h1 className='large text-center'>Neues Buch erstellen</h1>
      <form className='form' onSubmit={onFormSubmit}>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Title'
            name='title'
            value={title}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <textarea
            type='text'
            placeholder='Beschreibung'
            name='description'
            value={description}
            onChange={(e) => onChange(e)}
            rows='5'
            cols='1'
          ></textarea>
        </div>
        <div className='form-group'>
          <textarea
            type='text'
            placeholder='Leseprobe'
            name='reading'
            value={reading}
            onChange={(e) => onChange(e)}
            rows='10'
            cols='1'
          ></textarea>
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Seiten'
            name='pages'
            value={pages}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Amazone-Link'
            name='amazone'
            value={amazone}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Preis für Taschenbuch'
            name='pricePocketBook'
            value={pricePocketBook}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Preis für gebundenes Buch'
            name='priceHardBack'
            value={priceHardBack}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Preis für Ebook'
            name='priceEbook'
            value={priceEbook}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <input
            type='file'
            name='bookImage'
            id='bookImage'
            onChange={(e) => hanldeUploadChange(e)}
            hidden
          />
          <label htmlFor='bookImage' className='btn btn-dark'>
            Bild auswählen
          </label>
          <span id='file-chosen' className='text-light'>
            {image === null ? 'Kein Bild ausgewählt' : image.name}
          </span>
        </div>
        <input type='submit' className='btn btn-primary' value='Senden' />
      </form>
    </div>
  );
};

export default connect(null, { addNewBook })(CreateBook);
