import React from 'react';
import { Helmet } from 'react-helmet';

import ConsultingHead from './ConsultingHead';
import DynamicContent from '../../utils/DynamicContent';
import Anfrage from '../../utils/Anfrage';

const AbuseConsulting = () => {
  return (
    <div className='consulting'>
      <Helmet>
        <title>
          Beratung & Coaching Berlin Kreuzberg - Beratung in Missbrauchsfragen |
          Andreas Wolf von Guggenberger
        </title>
        <meta
          name='description'
          content='Ich mache keine Therapie, begleite aber, da sich hinter Sucht oft Missbrauchserfahrungen verbergen können, doch nicht müssen.

          Voraussetzung:
              • psychische Stabilität
              • bei Süchtigen mind. 7 Monate Trockenheit, da die Rückfallgefahr sonst zu groß ist.'
        />
        <meta
          name='keywords'
          content='Beratung, Coaching, Missbrauchsfragen, Begleitung, Berlin-Kreuzberg'
        />
      </Helmet>
      <ConsultingHead />
      <DynamicContent role='missbrauch' />
      <DynamicContent role='beratungs-preis' />
      <Anfrage />
    </div>
  );
};

export default AbuseConsulting;
