import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

const Painting = ({ photo }) => {
  return photo ? (
    <div className='gallery-details'>
      <h1 className='lead text-center'>{photo.title}</h1>
      <div className='gallery-img'>
        <img src={photo.imageURL} alt='' />
      </div>
      <div className='gallery-content'>
        <table className='text-bold'>
          <tbody>
            <tr>
              <td>​Format: </td>
              <td>{photo.format} </td>
            </tr>
            <tr>
              <td>Preis für das Foto: </td>
              <td>{photo.originalPrice} €</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='gallery-footer'>
        <p className='text-center text-bold'>{photo.printPrice}</p>
        <p className='text-center text-bold'>
          Wenn sie das Original oder einen Druck kaufen möchten kontaktieren sie
          mich bitte via Email oder Telefon.
        </p>
        <Link to='/kontakt' className='btn'>
          Kontakt
        </Link>
        <Link to='/fotos' className='btn'>
          Zurück
        </Link>
      </div>
    </div>
  ) : (
    <h4>Loading...</h4>
  );
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const photos = state.firestore.data.photos;
  const photo = photos ? photos[id] : null;
  return {
    photo: photo,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    {
      collection: 'photos',
    },
  ])
)(Painting);
