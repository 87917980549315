import React from 'react';

import ContactTable from './ContactTable';
import ContactMap from './ContactMap';

const Contact = () => {
  return (
    <div className='contact'>
      <div className='contact-top'>
        <div className='contact-details'>
          <ContactTable />
        </div>
      </div>
      <div className='contact-map'>
        <ContactMap />
      </div>
    </div>
  );
};

export default Contact;
