import React from 'react';
import { Helmet } from 'react-helmet';

import ConsultingHead from './ConsultingHead';
import DynamicContent from '../../utils/DynamicContent';
import Anfrage from '../../utils/Anfrage';

const AutogenTraining = () => {
  return (
    <div className='consulting'>
      <Helmet>
        <title>
          Beratung & Coaching Berlin Kreuzberg - Autogenes Training | Andreas
          Wolf von Guggenberger
        </title>
        <meta
          name='description'
          content='Bei Sucht und Krisen ist das Thema der Entspannung sehr wichtig. Darum biete ich einen 8 wöchigen Kurs Autogenes Training UND Stressverhalten an,
        indem wir auch auf ihre individuellen Muster eingehen werden.'
        />
        <meta
          name='keywords'
          content='Beratung, Coaching, Autogenes Training, Entspannung, Berlin-Kreuzberg'
        />
      </Helmet>
      <ConsultingHead />
      <h2 className='lead'>AUTOGENES TRAINING</h2>
      <p className='hypnose-parag'>
        Bei Sucht und Krisen ist das Thema der Entspannung sehr wichtig. Darum
        biete ich einen 8 wöchigen Kurs AUTOGENES TRAIING UND STRESSVERHALTEN
        an, indem wir auch auf ihre individuellen Muster eingehen werden.
      </p>
      <p className='hypnose-parag'>
        Prof. Shultz, Milton Erikson und viele andere erforschten in Amerika
        wissenschaftlich die Phänomene der Hypnose, worauf später auch die
        Lehren der Psychoneuroimmunologie aufbauten.{' '}
      </p>
      <p className='hypnose-parag'>
        In dieser Zeit erfand Prof. Shultz das Autogene Training, das auf zwei
        Prinzipien aufbaut:
      </p>
      <ul>
        <li>
          Absicht erzeugt in Nervenbahnen Aktionspotentiale, die zu Muskeln
          fließen und sie tief entspannen.
        </li>
        <li>
          Bedingter Reflex, der in Sekundenschnelle wirkt und wie beim
          Autofahren nie mehr verlernt wird.
        </li>
      </ul>
      <p className='hypnose-parag'>
        Das Autogene Training führt in Sekunden zu einer psychischen und
        physiologischen Ganzkörperentspannung. Es reguliert das Nervensystem,
        vertieft den Schlaf und kann bei Allergien,.., Bluthochdruck eingesetzt
        werden.
      </p>

      <h3 className='lead'>Preis: </h3>
      <ul>
        <li>240.00 €</li>
      </ul>
      <Anfrage />
    </div>
  );
};

export default AutogenTraining;
