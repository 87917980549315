import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { addNewContent } from '../../../redux/action/contentAction';

const CreateContent = ({ addNewContent }) => {
  const [formData, setFormData] = useState({
    category: '',
    role: '',
    title: '',
    text: '',
  });

  const { category, role, title, text } = formData;

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    addNewContent(formData);
    setFormData({
      category: '',
      role: '',
      title: '',
      text: '',
    });
  };

  return (
    <div className='dashboard-form'>
      <Link className='btn btn-light' to='/dashboard'>
        Zurück
      </Link>
      <h1 className='large text-center'>Neuen Inhalt einfügen</h1>
      <form className='form' onSubmit={onFormSubmit}>
        <div className='form-groupe'>
          <input
            type='text'
            placeholder='Kategorie'
            name='category'
            value={category}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className='form-groupe'>
          <input
            type='text'
            placeholder='Rolle'
            name='role'
            value={role}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className='form-groupe'>
          <input
            type='text'
            placeholder='Title'
            name='title'
            value={title}
            onChange={(e) => onChange(e)}
          />
          <div className='form-group'>
            <textarea
              type='text'
              placeholder='Inhalt'
              name='text'
              value={text}
              onChange={(e) => onChange(e)}
              rows='10'
              cols='1'
            ></textarea>
          </div>
        </div>
        <input type='submit' className='btn btn-primary' value='Senden' />
      </form>
    </div>
  );
};

export default connect(null, { addNewContent })(CreateContent);
