import { setAlert } from './alertAction';
import {
  CONTENT_ERROR,
  ADD_CONTENT,
  UPDATE_CONTENT,
  DELETE_CONTENT,
} from './actionTypes';

export const addNewContent = (formData) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  try {
    await firestore.collection('contents').add({
      category: formData.category,
      role: formData.role,
      title: formData.title,
      text: formData.text,
      createdAt: new Date(),
    });
    dispatch({ type: ADD_CONTENT, formData });

    dispatch(setAlert('Text erfolgreich hochgeladen.', 'success'));
  } catch (err) {
    dispatch({ type: CONTENT_ERROR, err });
  }
};

export const updateContent = (id, formData) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  try {
    await firestore.collection('contents').doc(id).update({
      category: formData.category,
      role: formData.role,
      title: formData.title,
      text: formData.text,
      createdAt: formData.createdAt,
    });
    dispatch({ type: UPDATE_CONTENT, formData });

    dispatch(setAlert('Text erfolgreich editiert.', 'success'));
  } catch (err) {
    dispatch({ type: CONTENT_ERROR, err });
  }
};

export const deleteContent = (id) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  if (window.confirm('Are you sure? This can NOT be undone!')) {
    const firestore = getFirestore();

    try {
      await firestore.collection('contents').doc(id).delete();

      dispatch({ type: DELETE_CONTENT });

      dispatch(setAlert('Text erfolgreich gelöscht.', 'success'));
    } catch (err) {
      dispatch({ type: CONTENT_ERROR, err });
    }
  }
};
