import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import ConsultingHead from './ConsultingHead';
import DynamicContent from '../../utils/DynamicContent';
import Anfrage from '../../utils/Anfrage';

const Hypnose = () => {
  return (
    <div className='consulting'>
      <Helmet>
        <title>
          Beratung & Coaching Berlin Kreuzberg - Autogenes Training | Andreas
          Wolf von Guggenberger
        </title>
        <meta
          name='description'
          content='Bei Sucht und Krisen ist das Thema der Entspannung sehr wichtig. Darum biete ich einen 8 wöchigen Kurs Autogenes Training UND Stressverhalten an,
        indem wir auch auf ihre individuellen Muster eingehen werden.'
        />
        <meta
          name='keywords'
          content='Hypnose, NLP, EMDR, Beratung, Berlin-Kreuzberg'
        />
      </Helmet>
      <ConsultingHead />
      <DynamicContent role='nlp' />
      <DynamicContent role='milton' />
      <DynamicContent role='funktioniert' />
      <p className='text-center text-bold hypnose-parag'>
        Einen hypnotischen Trancezustand
      </p>
      <h2 className='lead'>Was passiert in der Hypnose?</h2>
      <ul>
        <li>Die Hypnose funktioniert, weil wir die Anlage dazu haben.</li>
        <li>
          Hypnose ist eine physische Funktion: Unser willentliches Nervensystem
          der Bewegung wird beruhigt. Das erregt zugleich das Nervensystem der
          inneren Organe und der Verdauung, die mehr durchblutet werden.
        </li>
        <li>
          Dieser Zustand wird aktiviert, wenn sie ihre Aufmerksamkeit
          konzentriert nach innen richten und sie dort halten.
        </li>
        <li>
          Ihre Bewegungsmuskeln entspannen sich dabei, was ein Schweregefühl
          erzeugt. Dadurch werden sie durchblutet und fühlen sich warm an.
        </li>
        <li>
          Dieser Zustand wird dann mit Worten – Vorschlägen, und ihre
          Aufmerksamkeit verstärkt.
        </li>
        <li>
          Die Wirkung tritt ein, weil sie selbst die Vorschläge annehmen und
          umsetzen. So ist jede Hypnose, die Folge einer Selbstsuggestion:
        </li>
      </ul>
      <p className='hypnose-parag'>
        Mit geschlossenen Augen sitzen Sie und ich gebe ihnen eine sehr gesüßte
        Mandarine in den Mund. Davor sage ich - suggeriere ich, stellen Sie sich
        eine sehr helle und bittersaure Zitrone vor. Sie ist sauer und bitter.
        Was machte ich? Ich schlug ihnen dieses Bild vor, es sich vorzustellen.
        Sie taten es und akzeptieren es. Das Bild der Zitrone erweckte in ihnen
        Erinnerungen an eine Zitrone und ihren Geschmack. Dadurch wurden
        zugleich ihre bitter-sauren Geschmacksrezeptoren auf der Zunge aktiviert
        und die Anderen abgeschaltet. Indem Moment, wenn sie die mit Honig
        gesüßte Mandarine im Mund haben, werden Sie vielleicht das Gesicht
        verziehen, weil sie so eklig, bitter schmeckt.
      </p>
      <p className='hypnose-parag'>
        Wie läuft eine Sitzung ab? Dafür bitte ich sie, den Link
        <Link to='/online-consulting'>
          <span className='text-bold'> Onlineberatung</span>{' '}
        </Link>{' '}
        anzuklicken.
      </p>
      <p className='hypnose-parag'>
        Eine Weiterentwicklung der Hypnose – Trance durch das Seelenfeld Zuerst
        muss ich die, die nicht glauben, dass es die Seele gibt - sie eine Seele
        sind, um Toleranz bitten. Es ist Ihnen selbst überlassen, ob sie diese
        kurzen Zeilen lesen wollen.
      </p>
      <h2 className='lead'>
        Der Unterschied zwischen einer traditionellen Hypnose und einer
        seelischen Trance:
      </h2>
      <ul>
        <li>
          Bei einer Hypnose sind die Symptome durch die Wahrnehmungsrezeptoren
          genau zu bestimmen und zu begründen: Kälte/Wärme, Druck/Entspannung
          und Vibrationen im Körper. Durch die inneren Wahrnehmungsrezeptoren
          können wir empfinden, wo sich unsere Arme oder Beine befinden. Sie
          zeigen uns auch - mit Hilfe der Schmerzrezeptoren, Verletzungen an.
        </li>
        <li>
          Bei einer seelischen Trance fühlte der Klient oft eine Wärme, um sich
          herum, die stark entspannend wirkt – Reinigung seines Energiekörpers
          und der Meridiane. Sie wirkt stark aufhellend, entspannend und
          zentrierend, bei Manchen bis zu 3 Tagen.{' '}
        </li>
        <li>
          Diese Wärme, um ihn herum, kann er auch einatmen und sie mit seiner
          Aufmerksamkeit in seine Organe leiten. Sie Sie reinigt auch seine
          Meridiane, die sich dabei öffnen können. Sie wirkt als ausgleichende
          Reizbehandlung. Zugleich kann sie auch durch die Hände übertragen
          werden.
        </li>
      </ul>
      <p className='hypnose-parag'>
        In diesen 12 Jahren integrierte ich meine Seele und wurde mir ihrem
        Licht - mir selbst viel bewusster. So kann ich mich im Inneren
        seelisch-weich empfinden und zugleich außen die Spannungen und
        Empfindungen meiner Gefühls- und Gedankenfelder wahrnehmen. Diese
        Möglichkeit hat jeder. Ich kam per Zufall drauf. Dieser Prozess war die
        Integration und Bewusstwerdung meiner Seele und ihrem Licht. Ich
        erkannte mich als Seele und fand in ihr und mir wieder Heimat. Heute
        kann ich das Licht meiner Seele überall, an jedem Ort und in jeder
        Situation aktivieren. In dieser Zeit öffnete sich auch mein kreatives,
        schöpferisches Potential und mein Flow. Es begann stark zu fließen und
        sich in mir zu entfalten.
      </p>
      <p className='hypnose-parag'>
        Für Hintergründe, bitte ich Sie auf den Link{' '}
        <Link to='/books'>
          <span className='text-bold'> Meine Bücher</span>{' '}
        </Link>{' '}
        anzuklicken.
      </p>
      <DynamicContent role='neuro' />
      <h2 className='lead'>Wie arbeitet NLP?</h2>
      <p className='hypnose-parag'>NLP greift die Idee der Hypnose auf:</p>
      <ul>
        <li>
          Innere Repräsentanten – Symbole, z.B. eine Blume, die bei einer
          bestimmten Fragestellung aus dem Unterbewusstsein erscheint. Mit der
          Blume verbunden, sind die Erinnerungen und Anteile, die mit der Frage
          verbunden sind.
        </li>
      </ul>
      <p className='hypnose-parag'>
        Dies entspricht bei der Hypnose: Der Arbeit mit inneren
        Persönlichkeitsanteilen, die ich bei Beratungen beschreibe.
      </p>
      <h2 className='lead'>
        Das NLP versucht jetzt diese inneren Repräsentanten nach außen:
      </h2>
      <ul>
        <li>auf ein Papier mit einer Blume oder</li>
        <li>einem Püppchen zu projizieren,</li>
        <li>oder auf eine "Zeitlinie des Lebens" - Kreidestrich am Boden.</li>
        <li>....</li>
      </ul>
      <p className='hypnose-parag'>
        Wenn sich der Klient auf das Papier stellt,{' '}
      </p>
      <ul>
        <li>ist er gefühlsmäßig damit verbunden - Assoziation.</li>
        <li>
          Er kann sich aber auch daneben stellen, um so seine Erfahrungen zu
          betrachten - Dissoziation. Aus dieser Position kann er sie
          analysieren, um darin alternative Verhalten und Potentiale zu
          erkennen.
        </li>
      </ul>
      <p className='hypnose-parag'>
        Für mehr Infos können sie den Link{' '}
        <Link to='/online-consulting'>
          <span className='text-bold'> Onlineberatung</span>{' '}
        </Link>{' '}
        anklicken.
      </p>
      <DynamicContent role='emdr' />
      <DynamicContent role='dazu' />
      <DynamicContent role='sozial' />
      <Anfrage />
    </div>
  );
};

export default Hypnose;
