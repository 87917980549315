import React from 'react';
import { Helmet } from 'react-helmet';

import ConsultingHead from './ConsultingHead';
import DynamicContent from '../../utils/DynamicContent';
import Anfrage from '../../utils/Anfrage';

const AddictionConsulting = () => {
  return (
    <div className='consulting'>
      <Helmet>
        <title>
          Beratung & Coaching Berlin Kreuzberg - Suchtberatung | Andreas Wolf
          von Guggenberger
        </title>
        <meta
          name='description'
          content='Ich berate und begleite Menschen die mit Alkohol oder Drogen aufhören wollen und unterstütze sie auf ihrem Weg.'
        />
        <meta
          name='keywords'
          content='Beratung, Coaching, Suchtberatung, Gutachten, Berlin-Kreuzberg'
        />
      </Helmet>
      <ConsultingHead />
      <DynamicContent role='suchtberatung' />
      <h2 className='lead'>VERLAUF DER SUCHT:</h2>
      <p className='hypnose-parag'>
        Der Süchtige merkt, dass bei ihm etwas nicht stimmt:
      </p>
      <ul>
        <li>
          Er beobachtet sich und versucht die Droge zu kontrollieren. Dabei
          rutscht er tiefer in die Sucht – Zwanghaftigkeit, ständige
          Schuldgefühle, Angstzustände, Depression und Scham wachsen. Süchtige
          leben in der ständigen Angst, das man ihre innere Ohnmacht, Einsamkeit
          und ihr Versagen entdeckt.
        </li>
        <li>
          Sie beginnen Sachen zu vernachlässigen, verschulden sich, stehlen und
          können gewalttätig werden,….
        </li>
        <li>Langsam verlieren sie sich und verrohen.</li>
        <li>
          Angstzustände, Schlaflosigkeit und ständiger Beschaffungsstress nehmen
          zu.
        </li>
        <li>Alkoholberatung mit Entzug und Therapie folgen</li>
        <li>oder Krankheit, Selbstmord und Tod.</li>
      </ul>
      <p className='hypnose-parag'>Bei Alkoholikern gibt es:</p>
      <ul>
        <li>Tägliche Trinker</li>
        <li>Quartalstrinker</li>
        <li>Pegeltrinker</li>
        <li>Versteckte Trinker</li>
        <li>K.O. Trinker</li>
        <li>....</li>
      </ul>
      <p className='hypnose-parag'>
        Der Süchtige schadet und verletzt sich selbst und andere.
      </p>
      <DynamicContent role='energie' />
      <DynamicContent role='gruppen' />
      <DynamicContent role='wo' />
      <DynamicContent role='beratungs-preis' />
      <Anfrage />
    </div>
  );
};

export default AddictionConsulting;
