export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_FAIL = 'RESET_FAIL';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNOUT_ERROR = 'SIGNOUT_ERROR';
export const BOOK_ERROR = 'BOOK_ERROR';
export const ADD_BOOK = 'ADD_BOOK';
export const UPDATE_BOOK = 'UPDATE_BOOK';
export const DELETE_BOOK = 'DELETE_BOOK';
export const ADD_GUEST_ENTRY = 'ADD_GUEST_ENTRY';
export const CONTENT_ERROR = 'CONTENT_ERROR';
export const ADD_CONTENT = 'ADD_CONTENT';
export const UPDATE_CONTENT = 'UPDATE_CONTENT';
export const DELETE_CONTENT = 'DELETE_CONTENT';
export const PAINTING_ERROR = 'PAINTING_ERROR';
export const UPLOAD_PAINTING = 'UPLOAD_PAINTING';
export const UPDATE_PAINTING = 'UPDATE_PAINTING';
export const DELETE_PAINTING = 'DELETE_PAINTING';
export const PHOTO_ERROR = 'PHOTO_ERROR';
export const UPLOAD_PHOTO = 'UPLOAD_PHOTO';
export const UPDATE_PHOTO = 'UPDATE_PHOTO';
export const DELETE_PHOTO = 'DELETE_PHOTO';
export const VIDEO_ERROR = 'VIDEO_ERROR';
export const UPLOAD_VIDEO = 'UPLOAD_VIDEO';
export const UPDATE_VIDEO = 'UPDATE_VIDEO';
export const DELETE_VIDEO = 'DELETE_VIDEO';
export const DRAWING_ERROR = 'DRAWING_ERROR';
export const UPLOAD_DRAWING = 'UPLOAD_DRAWING';
export const UPDATE_DRAWING = 'UPDATE_DRAWING';
export const DELETE_DRAWING = 'DELETE_DRAWING';
