import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

const Drawing = ({ drawing }) => {
  return drawing ? (
    <div className='gallery-details'>
      <h1 className='lead text-center'>{drawing.title}</h1>
      <div className='gallery-img'>
        <img src={drawing.imageURL} alt='drawing' />
      </div>
      <div className='gallery-content'>
        <table className='text-bold'>
          <tbody>
            <tr>
              <td>Material:</td>
              <td>{drawing.material}</td>
            </tr>
            <tr>
              <td>​Format: </td>
              <td>{drawing.format} </td>
            </tr>
            <tr>
              <td>Preis: </td>
              <td>{drawing.originalPrice} €</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='gallery-footer'>
        <p className='text-center text-bold'>
          Druck mit Photoshop bearbeitet, signiert.
        </p>
        <p className='text-center text-bold'>
          Preis auf Anfrage - je nach Format
        </p>
        <p className='text-center text-bold'>
          Wenn sie das Original oder einen Druck kaufen möchten kontaktieren sie
          mich bitte via Email oder Telefon.
        </p>
        <Link to='/kontakt' className='btn'>
          Kontakt
        </Link>
        <Link to='/zeichnungen' className='btn'>
          Zurück
        </Link>
      </div>
    </div>
  ) : (
    <h4>Loading...</h4>
  );
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const drawings = state.firestore.data.drawings;
  const drawing = drawings ? drawings[id] : null;
  return {
    drawing: drawing,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    {
      collection: 'drawings',
    },
  ])
)(Drawing);
