import {
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SIGNOUT_SUCCESS,
  SIGNOUT_ERROR,
} from '../action/actionTypes';

const initState = {
  authError: null,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      console.log('login success');
      return {
        ...state,
        authError: null,
      };
    case LOGIN_ERROR:
      console.log('login error');
      return {
        ...state,
        authError: 'Login failed',
      };
    case SIGNOUT_SUCCESS:
      console.log('signout success');
      return state;
    case SIGNOUT_ERROR:
      console.log('signout failed');
      return {
        ...state,
        authError: 'signout failed',
      };
    case SIGNUP_SUCCESS:
      console.log('signup success');
      return {
        ...state,
        authError: null,
      };
    case SIGNUP_ERROR:
      console.log('signup failed');
      return {
        ...state,
        authError: 'signup failed',
      };
    default:
      return state;
  }
};

export default authReducer;
