import React from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import markerIcon from '../../images/marker-icon.png';

const ContactMap = () => {
  const position = [52.4921, 13.382733];

  const myIcon = L.icon({
    iconUrl: markerIcon,
    iconSize: [25, 31],
    iconAnchor: [0, 0],
    popupAnchor: [12, 10],
  });

  return (
    <MapContainer center={position} zoom={17}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      <Marker position={position} icon={myIcon}>
        <Popup>
          <div className='popup-card'>
            <h6 className='popup-card-title text-center'>Hallo</h6>
          </div>
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default ContactMap;
