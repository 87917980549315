import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

const DrawingList = ({ drawings }) => {
  return (
    <div className='gallery-inner'>
      <Link className='btn btn-light' to='/dashboard'>
        Zurück
      </Link>
      <h1 className='lead text-center'>
        Wähle eine Zeichnung zum editieren aus
      </h1>
      <div className='gallery-container'>
        {drawings &&
          drawings.map((drawing) => (
            <Link to={`/edit-drawing/${drawing.id}`} key={drawing.id}>
              <img src={drawing.imageURL} alt='Gemälde' />
              <p>{drawing.title}</p>
            </Link>
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  drawings: state.firestore.ordered.drawings,
});

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: 'drawings', orderBy: ['createdAt', 'asc'] }])
)(DrawingList);
