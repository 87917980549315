import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const NotFound = ({ location }) => {
  const str = location.pathname.split('.');
  const redirectPath = str[0] === '/index' ? '/' : str[0];

  return (
    <div className='notFound'>
      <Helmet>
        <title>404</title>
        <meta
          name='description'
          content='This is the 404 page. You have been redirected here because the page you requested does not exist! You can see my homepage'
        />
      </Helmet>
      {str[1] === 'php' && <Redirect to={redirectPath} />}

      <h1 className='large text-center'>
        <i className='fas fa-exclamation-triangle'></i> Seite nicht gefunden
      </h1>
      <p className='lead text-center'>
        Bitte überprüfen sie die Schreibweise. Drücken sie den Button "Homepage"
        um auf die Hauptseite geleitet zu werden.{' '}
      </p>
      <div className='btn-container'>
        <Link to='/' className='btn notFoundBtn'>
          Homepage{' '}
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
