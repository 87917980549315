import React from 'react';
import { Link } from 'react-router-dom';

const Anfrage = () => {
  return (
    <div className='contact-link'>
      <h2>
        Anfragen und Bestellungen:{' '}
        <Link to='/kontakt' className='btn'>
          Kontakt
        </Link>
      </h2>
    </div>
  );
};

export default Anfrage;
