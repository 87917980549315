import {
  VIDEO_ERROR,
  UPLOAD_VIDEO,
  UPDATE_VIDEO,
  DELETE_VIDEO,
} from '../action/actionTypes';

const initState = {};

const videoReducer = (state = initState, action) => {
  switch (action.type) {
    case UPLOAD_VIDEO:
      console.log('created video');
      return state;
    case UPDATE_VIDEO:
      console.log('video updated');
      return state;
    case DELETE_VIDEO:
      console.log('video delete');
      return state;
    case VIDEO_ERROR:
      console.log('create video error', action.err);
      return state;
    default:
      return state;
  }
};

export default videoReducer;
