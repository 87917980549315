import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

import {
  updatePainting,
  deletePainting,
} from '../../../redux/action/paintingAction';

const EditPainting = ({
  updatePainting,
  deletePainting,
  painting,
  match,
  history,
}) => {
  const [formData, setFormData] = useState({
    title: '',
    material: '',
    format: '',
    imageName: '',
    imageURL: '',
    originalPrice: '',
    printPrice: '',
    createdAt: '',
  });

  useEffect(() => {
    if (painting) {
      setFormData({
        title: painting.title,
        material: painting.material,
        format: painting.format,
        imageName: painting.imageName,
        imageURL: painting.imageURL,
        originalPrice: painting.originalPrice,
        printPrice: painting.printPrice,
        createdAt: painting.createdAt,
      });
    }
  }, [painting]);

  const { title, material, format, originalPrice, printPrice } = formData;

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    const id = match.params.id;

    updatePainting(id, formData);
    history.push('/dashboard');
  };

  const handleDeletePainting = (e) => {
    e.preventDefault();

    const id = match.params.id;
    const imageName = formData.imageName;

    deletePainting(id, imageName);
    history.push('/dashboard');
  };

  return (
    <div className='dashboard-form'>
      <Link className='btn btn-light' to='/dashboard'>
        Zurück
      </Link>
      <h1 className='large text-center'>Bild bearbeiten</h1>
      <form className='form' onSubmit={onFormSubmit}>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Title'
            name='title'
            value={title}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Material'
            name='material'
            value={material}
            onChange={(e) => onChange(e)}
          ></input>
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Format'
            name='format'
            value={format}
            onChange={(e) => onChange(e)}
          ></input>
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Original Preis'
            name='originalPrice'
            value={originalPrice}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Preis für Druck'
            name='printPrice'
            value={printPrice}
            onChange={(e) => onChange(e)}
          />
        </div>
        <input type='submit' className='btn btn-primary' value='Senden' />
        <button className='btn btn-danger' onClick={handleDeletePainting}>
          Löschen
        </button>
      </form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const paintings = state.firestore.data.paintings;
  const painting = paintings ? paintings[id] : null;
  return {
    painting: painting,
  };
};

export default compose(
  connect(mapStateToProps, { updatePainting, deletePainting }),
  firestoreConnect([
    {
      collection: 'paintings',
    },
  ])
)(withRouter(EditPainting));
