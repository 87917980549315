import React from 'react';

const ContactTable = () => {
  return (
    <table>
      <tbody>
        <tr>
          <td className='td-left'>
            <i className='fas fa-phone-volume' />
          </td>
          <td className='td-right'>0049 (0) 176 326 51 517</td>
        </tr>
        <tr>
          <td className='td-left'>
            <i className='fas fa-address-card' />
          </td>
          <td className='td-right'>
            Andreas Wolf von Guggenberger Grossbeerenstraße 34 10965 Berlin
            Deutschland
          </td>
        </tr>
        <tr>
          <td className='td-left'>
            <i className='fas fa-at' />
          </td>
          <td className='td-right'>
            <a href='mailto:vonguggenberger@freenet.de'>
              vonguggenberger@freenet.de
            </a>
          </td>
        </tr>
        <tr>
          <td className='td-left'>
            <i className='fab fa-facebook-square'></i>
          </td>
          <td className='td-right'>
            <a
              href='https://www.facebook.com/seelenlichtraum.de/'
              target='_blank'
              rel='noopener noreferrer'
            >
              www.facebook.com/seelenlichtraum.de
            </a>
          </td>
        </tr>
        <tr>
          <td className='td-left'>
            <i className='fab fa-twitter'></i>
          </td>
          <td className='td-right'>
            <a
              href='https://twitter.com/wolguggenberger?lang=en'
              target='_blank'
              rel='noopener noreferrer'
            >
              @Wolguggenberger
            </a>
          </td>
        </tr>
        <tr>
          <td className='td-left'>
            <i className='fab fa-instagram'></i>
          </td>
          <td className='td-right'>
            <a
              href='https://www.instagram.com/vonguggenberger/'
              target='_blank'
              rel='noopener noreferrer'
            >
              @vonguggenberger
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ContactTable;
