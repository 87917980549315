import React from 'react';
import SignedInLinks from './SignedInLinks';
import SignedOutLinks from './SignedOutLinks';
import { connect } from 'react-redux';

const Navbar = ({ auth, profile }) => {
  const toogleNavbar = () => {
    let nav = document.getElementById('customNavbar');
    if (nav.className === 'navbar') {
      nav.className += ' responsive';
    } else {
      nav.className = 'navbar';
    }
  };

  const links = auth.uid ? (
    <SignedInLinks profile={profile} />
  ) : (
    <SignedOutLinks toogleNavbar />
  );

  return (
    <div className='navbar' id='customNavbar'>
      {links}
      <button className='icon' onClick={toogleNavbar}>
        <i className='fa fa-bars'></i>
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps)(Navbar);
