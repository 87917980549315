import {
  PHOTO_ERROR,
  UPLOAD_PHOTO,
  UPDATE_PHOTO,
  DELETE_PHOTO,
} from '../action/actionTypes';

const initState = {};

const paintingReducer = (state = initState, action) => {
  switch (action.type) {
    case UPLOAD_PHOTO:
      console.log('photo uploaded');
      return state;
    case UPDATE_PHOTO:
      console.log('photo updated');
      return state;
    case DELETE_PHOTO:
      console.log('photo delete');
      return state;
    case PHOTO_ERROR:
      console.log('create photo error', action.err);
      return state;
    default:
      return state;
  }
};

export default paintingReducer;
