import React from 'react';
import profileImage from '../../images/profile.png';

const ConsultingHead = () => {
  return (
    <div className='consulting-head'>
      <h1 className='text-center'>Beratung - Coaching</h1>
      <div className='consulting-header'>
        <div className='head-img'>
          <img src={profileImage} alt='profile' />
        </div>
        <div className='head-text'>
          <p>
            Mein Name ist Andreas, Wolf von Guggenberger. Gerne berate und
            begleite ich sie bei mir oder auch Online.{' '}
          </p>
          <br />
          <p>Ich berate und begleite Menschen:</p>
          <ul>
            <li>In Lebenskrisen</li>
            <li>Beim Wunsch mit Drogen aufzuhören</li>
            <li>Bei Missbrauch</li>
            <li>Umorientierungen</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ConsultingHead;
