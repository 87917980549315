import React from 'react';
import { Helmet } from 'react-helmet';

import ConsultingHead from './ConsultingHead';
import DynamicContent from '../../utils/DynamicContent';
import Anfrage from '../../utils/Anfrage';

const Reiztraining = () => {
  return (
    <div className='consulting'>
      <Helmet>
        <title>
          Beratung & Coaching Berlin Kreuzberg - ART Assoziatives Reiztraining |
          Andreas Wolf von Guggenberger
        </title>
        <meta
          name='description'
          content='Mit Hilfe des Assoziativen Reiztrainings - ART, lernen sie zielgerichtet Organe über das Nervensystem zu beeinflussen und ihre Funktionen auszugleichen. Damit können Therapien des Arztes, zielgerichtet und gesamtheitlich unterstützt werden. Zugleich wird ihnen ihr schöpferisches Prinzip bewusst werden, das ihre Kreativität steigern wird und in ihnen zu einer verstärkten Lösungsfindung führen wird.'
        />
        <meta
          name='keywords'
          content='Beratung, Coaching, ART, Assoziatives Reiztraining, Berlin-Kreuzberg'
        />
      </Helmet>
      <ConsultingHead />
      <h2 className='lead'>AST - ASSOZIATIVES REIZTRAINING</h2>
      <p className='hypnose-parag'>
        vertieft die Funktionen und Erkenntnisse des Autogenen Trainings durch
        folgende Prinzipien:
      </p>
      <ul>
        <li>Stärkung der Konzentrations-, Geist- und Gedankenkraft</li>
        <li>das Wesen der Assoziationen und ihrer Reize</li>
        <li>
          Bewusstwerdung des eigenen Verhaltens und die Erarbeitung
          individueller Entspannungsmuster
        </li>
        <li>Die Rolle der Evolution in unserem Verhalten</li>
        <li>Chakren</li>
        <li>Die geistige Rolle ihres Herzens und ihrer Seele</li>
      </ul>
      <p className='hypnose-parag'>
        Mit Hilfe des Assoziativen Reiztrainings - ART, lernen sie zielgerichtet
        Organe über das Nervensystem zu beeinflussen und ihre Funktionen
        auszugleichen. Damit können Therapien des Arztes, zielgerichtet und
        gesamtheitlich unterstützt werden. Zugleich wird ihnen ihr
        schöpferisches Prinzip bewusst werden, das ihre Kreativität steigern
        wird und in ihnen zu einer verstärkten Lösungsfindung führen wird.
      </p>
      <DynamicContent role='reizpreis' />
      <Anfrage />
    </div>
  );
};

export default Reiztraining;
