import React from 'react';
import { Helmet } from 'react-helmet';

import profileImage from '../../images/profile.jpg';
import DynamicContent from '../../utils/DynamicContent';
import Anfrage from '../../utils/Anfrage';

const Classic = () => {
  return (
    <div className='consulting'>
      <Helmet>
        <title>
          Klassische Massage Berlin Kreuzberg - mit oder ohne Energiearbeit |
          Andreas Wolf von Guggenberger
        </title>
        <meta
          name='description'
          content='Ich arbeite mit Öl, rhythmischen Streichungen, Knettungen und Vibrationen an ihrem Körper, um ihre Muskeln zu lockern. Mit wellenartigen Rhythmen und Wechsel der Druckstärken, führe ich Sie in eine wohltuende, sie regenerierende und tiefe Entspannung.'
        />
        <meta
          name='keywords'
          content='Massage, Klassische Massage, Energiearbeit, Berlin-Kreuzberg'
        />
      </Helmet>
      <div className='consulting-head'>
        <h1 className='text-center'>Klassische Massage</h1>
        <div className='consulting-header'>
          <div className='head-img'>
            <img src={profileImage} alt='profile' />
          </div>
          <div className='head-text'>
            <p>
              Ich biete Ihnen entspannende, beruhigende und Sie wieder
              zentrierende Massagen an:
            </p>
            <ul>
              <li>
                KLASSISCHE MASSAGE wirkt über die Muskeln tief entspannend.
              </li>
              <li>
                FUSSREFLEX- UND SEGMENTMASSAGE, die über Reflexpunkte und
                Energiebahnen auf die Organe wirken.
              </li>
              <li>
                ENERGIE- UND CHAKRENARBEIT, die ihre Selbstheilungskräfte
                stärken, ihre Meridiane reinigen und öffnen
              </li>
              <li>Eine Kombination dieser Möglichkeiten</li>
            </ul>
          </div>
        </div>
      </div>
      <DynamicContent role='klassisch' />
      <DynamicContent role='massage-preis' />
      <Anfrage />
    </div>
  );
};

export default Classic;
