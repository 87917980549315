import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

import BookItem from './BookItem';
import DynamicContent from '../../utils/DynamicContent';

const Books = ({ books }) => {
  return books ? (
    <div className='books'>
      <Helmet>
        <title>
          Meine Autorenseite - Berlin | Andreas wolf von Guggenberger
        </title>
        <meta
          name='description'
          content='Meine Bücher entstanden autodidaktisch in 8 bis 10 Jahren. Es ging mir anfangs nie um Religion. Ich bezeichnete mich ja als Atheist. Zugleich war ich aber auf der Suche nach einer Verbundenheit zum Leben. Ich spürte einen inneren Drang, der mich zweifeln liess und ständige Fragen ans Leben provozierte. Ich konnte diesen Drang nicht beschreiben. Ein inneres Leiden brachte mich auf die Fragen der Heilung und Selbsterforschung. Andreas, Wolf von Guggenberger'
        />
        <meta name='keywords' content='Autor, Berlin, Bücher, Gedichte' />
      </Helmet>
      <div className='book-content'>
        <DynamicContent role='autor' />
      </div>
      <div className='book-cover'>
        {books.map((book) => (
          <BookItem key={book.id} book={book} />
        ))}
      </div>
    </div>
  ) : (
    <h4>Loading...</h4>
  );
};

const mapStateToProps = (state) => ({
  books: state.firestore.ordered.books,
});

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: 'books', orderBy: ['createdAt', 'asc'] }])
)(Books);
