import firebase from '../../fbConfig';

import { setAlert } from './alertAction';
import {
  PAINTING_ERROR,
  UPLOAD_PAINTING,
  UPDATE_PAINTING,
  DELETE_PAINTING,
} from './actionTypes';

const storage = firebase.storage();

export const uploadPainting = (formData, image) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  try {
    const storageRef = storage.ref('images');
    const fileRef = storageRef.child(image.name);
    await fileRef.put(image);
    await firestore.collection('paintings').add({
      title: formData.title,
      material: formData.material,
      format: formData.format,
      imageName: image.name,
      imageURL: await fileRef.getDownloadURL(),
      originalPrice: formData.originalPrice,
      printPrice: formData.printPrice,
      createdAt: new Date(),
    });
    dispatch({ type: UPLOAD_PAINTING, formData });

    dispatch(setAlert('Bild erfolgreich hochgeladen.', 'success'));
  } catch (err) {
    dispatch({
      type: PAINTING_ERROR,
      err,
    });
  }
};

export const updatePainting = (id, formData) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();
  console.log(id);
  try {
    await firestore.collection('paintings').doc(id).update({
      title: formData.title,
      material: formData.material,
      format: formData.format,
      imageName: formData.imageName,
      imageURL: formData.imageURL,
      originalPrice: formData.originalPrice,
      printPrice: formData.printPrice,
      createdAt: formData.createdAt,
    });
    dispatch({ type: UPDATE_PAINTING, formData });

    dispatch(setAlert('Bild erfolgreich editiert.', 'success'));
  } catch (err) {
    dispatch({
      type: PAINTING_ERROR,
      err,
    });
  }
};

export const deletePainting = (id, imageName) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  if (window.confirm('Are you sure? This can NOT be undone!')) {
    const firestore = getFirestore();

    try {
      const storageRef = storage.ref('images').child(`${imageName}`);
      await storageRef.delete();

      await firestore.collection('paintings').doc(id).delete();

      dispatch({ type: DELETE_PAINTING });

      dispatch(setAlert('Bild erfolgreich gelöscht.', 'success'));
    } catch (err) {
      dispatch({
        type: PAINTING_ERROR,
        err,
      });
    }
  }
};
