import React from 'react';
import { Link } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import { Helmet } from 'react-helmet';

import profileImage from '../../images/profile.png';

const Home = () => {
  return (
    <div className='home'>
      <Helmet>
        <title>
          Andreas Wolf von Guggenberger - Von Grapologie, Beratung und Coaching
          über Malerei, Fotografie und seinen Büchern | Berlin Kreuzberg
        </title>
        <meta
          name='description'
          content='Sehr geehrte Damen und Herren
          Ich begrüße Sie auf meiner Berater-, Grapologie-, Massage- und Autorenseite.
          Vertiefte Informationen über mein Berufsfeld der Graphologie, Beratung, Massage, Hypnose und meine Bücher finden Sie unter:
          www.seelenlichtraum.de
          www.wolfvonguggenberger.de'
        />
        <meta
          name='keywords'
          content='Graphologie, Massage, Beratung, Coaching, Bücher, Berlin-Kreuzberg, Geistheilung'
        />
      </Helmet>
      <div className='home-content'>
        <div className='home-img'>
          <img src={profileImage} alt='profil' />
        </div>
        <h2 className='lead text-center'>Wolf von Guggenberger</h2>
        <h2 className='lead text-center home-heading'>Andreas</h2>
        <p className='beratung-p text-center text-bold'>0176 326 51 517</p>
        <div className='home-links'>
          <div className='home-main-container'>
            <h2>
              Beratung & <br />
              Coaching
            </h2>
            <p className='beratung-p'>
              Für Menschen, die sich umorientieren wollen, unter Ängsten,
              Unsicherheiten und Süchten leiden, biete ich:
            </p>
            <br />
            <Link className='homeBtn' to='/online-consulting'>
              <span>
                Beratungen <br />
                auch online möglich
              </span>
            </Link>
            <Link className='homeBtn' to='/graphology-consulting'>
              <span>Graphologische Beratung</span>
            </Link>
            <Link className='homeBtn' to='/hypnose'>
              <span>Hypnose, NLP, EMDR</span>
            </Link>
            <Link className='homeBtn' to='/suchtberatung'>
              <span>Suchtberatung</span>
            </Link>
            <Link className='homeBtn' to='/missbrauchsberatung'>
              <span>Missbrauchsfragen</span>
            </Link>
            <Link className='homeBtn' to='/ausbildung'>
              <span>Meine Ausbildungen</span>
            </Link>
          </div>
          <div className='home-main-container'>
            <h2>
              Graphologische <br />
              Gutachten
            </h2>
            <p className='beratung-p'>
              Für Personal-, Berufsberater und Leuten, die sich privat und
              beruflich verändern, biete ich:
            </p>
            <br />
            <Link className='homeBtn' to='/graphology'>
              <span>Geschichte der Graphologie</span>
            </Link>
            <div className='home-link-heading'>
              <ul>
                <li>
                  <h4>Für wen ist die Graphologie geeignet?</h4>
                </li>
                <li>
                  <h4>Was beschreibt die Graphologie?</h4>
                </li>
                <li>
                  <h4>Wie geschieht dies?</h4>
                </li>
                <li>
                  <h4>Was kann man in einem Schriftbild erkennen?</h4>
                </li>
                <li>
                  <h4>Schriftbeispiele</h4>
                </li>
                <li>
                  <h4>Was ich für ein Gutachten brauche?</h4>
                </li>
              </ul>
            </div>
            <Link className='homeBtn' to='/graphology-consulting'>
              <span>Beratung anhand des Gutachtens</span>
            </Link>
          </div>
          <div className='home-main-container'>
            <h2>Entspannung - Aktiv</h2>
            <Link className='homeBtn' to='/autogenes-training'>
              <span>Autogenes Training nach Prof. Schultz</span>
            </Link>
            <Link className='homeBtn home-heading' to='/reiztraining'>
              <span>AST - Assoziatives Reiztraining</span>
            </Link>
            <h2>Massagen - Passiv</h2>
            <Link className='homeBtn' to='/klassische-massage'>
              <span>Klassische Massage</span>
            </Link>
            <Link className='homeBtn' to='/segment-massage'>
              <span>Segmentmassage</span>
            </Link>
            <Link className='homeBtn' to='/reflex-massage'>
              <span>Fussreflexmassage</span>
            </Link>
            <Link className='homeBtn' to='/chakra-arbeit'>
              <span>Energiebehandlung</span>
            </Link>
            <Link className='homeBtn' to='/chakra-arbeit'>
              <span>Chakrenarbeit und Reinigung</span>
            </Link>
          </div>
          <div className='home-main-container'>
            <h2>Meine Bücher</h2>
            <Link className='homeBtn' to='/books'>
              <span>Willkommen auf meiner Autorenseite:</span>
            </Link>
            <div className='home-link-heading'>
              <ul>
                <li>
                  <h4>Evolution der Schöpfung und der Seele</h4>
                </li>
                <li>
                  <h4>Der Mensch und die Wirkung seiner Seele</h4>
                </li>
                <li>
                  <h4>Wandern auf dem inneren Weg</h4>
                </li>
                <li>
                  <h4>Liebe</h4>
                </li>
                <li>
                  <h4>Was bin, ich wenn ich bin.</h4>
                </li>
                <li>
                  <h4>Sperrzone Berlin</h4>
                </li>
                <li>
                  <h4>Gedichte und Bilder</h4>
                </li>
                <li>
                  <h4>und weitere Bücher</h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='home-text'>
          <p>
            Neben einer Ausbildung zum medizinischen Masseur, absolvierte ich -
            Wolf von Guggenberger, eine Hypnoseausbildung (nach Milton Erikson).
            Ich erweiterte diese mit dem Neurolinguistischem Programmieren (NLP)
            bis zum Mastergrad. Zur Vertiefung folgten Kurse in systemischer
            Hypnotherapie und Trauma-Arbeit - EMDR. Auch wollte ich meinen
            Kunden Methoden mitgeben, mit denen sie sich aktiv entspannen
            können. Dafür lernte ich folgende Entspannungsverfahren: Leiter für
            Autogenes Training nach J.H. Schultz und Shivananda
            Yogalehrerausbildung. Mit Hilfe der Graphologie und Psychoanalyse
            lernte ich die inneren Dynamiken des Menschen besser zu verstehen
            und tiefenpsychologische Handschriftenprofile zu erstellen.
          </p>
          <p>
            In meiner Arbeit als freier Graphologe - FVDG, baue ich auf
            Erkenntnissen von Freud, Adler und Ludwig Klages auf und erstelle
            aus Handschriften psychologische Persönlichkeitsprofile. Ferner
            biete ich Einzelcoachings, Suchtberatungen, Seminare und Vorträge
            an. Um meine Erfahrungen zu vertiefen, setzte ich mich praktisch mit
            den Wegen des Sufismus, der Advaitaphilosophie und dem Herzensgebet
            auseinander. Ab einem Punkt fragte ich mich, wer ich in diesen Welt-
            und Schöpfungsbildern selbst war. Damit begann meine fruchtbarste
            und schöpferischste Zeit. Ich fand meinen eigenen Weg, den ich in
            meinen Büchern formulierte.
          </p>
        </div>
        <div className='home-gallery'>
          <div className='home-books'>
            <h2 className='text-center'>Meine Zeichnungen</h2>
            <h2 className='text-center'>Bücher, Fotos</h2>
            <h2 className='text-center'>Gemälde</h2>
            <p>
              <Link className='homeBtn' to='/bilder'>
                <span>Gemälde</span>
              </Link>
            </p>
            <p>
              <Link className='homeBtn' to='/zeichnungen'>
                <span>Zeichnungen</span>
              </Link>
            </p>
            <p>
              <Link className='homeBtn' to='/fotos'>
                <span>Fotos</span>
              </Link>
            </p>
            <p>
              <Link className='homeBtn' to='/videos'>
                <span>Videos</span>
              </Link>
            </p>
            <p>
              <Link className='homeBtn' to='/books'>
                <span>Bücher</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
      <CookieConsent
        location='bottom'
        buttonText='Ich habe verstanden'
        cookieName='bannerCookie'
        style={{ background: '#2B373B' }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
        expires={1}
      >
        Diese Webseite verwendet nur funktionale Cookies.
      </CookieConsent>
    </div>
  );
};

export default Home;
