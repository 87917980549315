import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

const PaintingList = ({ paintings }) => {
  return (
    <div className='gallery-inner'>
      <Link className='btn btn-light' to='/dashboard'>
        Zurück
      </Link>
      <h1 className='lead text-center'>Wähle ein Bild zum editieren aus</h1>
      <div className='gallery-container'>
        {paintings &&
          paintings.map((painting) => (
            <Link to={`/edit-painting/${painting.id}`} key={painting.id}>
              <img src={painting.imageURL} alt='Gemälde' />
              <p>{painting.title}</p>
            </Link>
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  paintings: state.firestore.ordered.paintings,
});

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: 'paintings', orderBy: ['createdAt', 'asc'] }])
)(PaintingList);
