import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

const Painting = ({ painting }) => {
  return painting ? (
    <div className='gallery-details'>
      <h1 className='lead text-center'>{painting.title}</h1>
      <div className='gallery-img'>
        <img src={painting.imageURL} alt='painting' />
      </div>
      <div className='gallery-content'>
        <table className='text-bold'>
          <tbody>
            <tr>
              <td>Material:</td>
              <td>{painting.material}</td>
            </tr>
            <tr>
              <td>​Format: </td>
              <td>{painting.format} </td>
            </tr>
            <tr>
              <td>Preis: </td>
              <td>{painting.originalPrice} </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='gallery-footer'>
        <p className='text-center text-bold'>{painting.printPrice}</p>
        <p className='text-center text-bold'>
          Preis auf Anfrage - je nach Format
        </p>
        <p className='text-center text-bold'>
          Wenn sie das Original oder einen Druck kaufen möchten kontaktieren sie
          mich bitte via Email oder Telefon.
        </p>
        <Link to='/kontakt' className='btn'>
          Kontakt
        </Link>
        <Link to='/bilder' className='btn'>
          Zurück
        </Link>
      </div>
    </div>
  ) : (
    <h4>Loading...</h4>
  );
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const paintings = state.firestore.data.paintings;
  const painting = paintings ? paintings[id] : null;
  return {
    painting: painting,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    {
      collection: 'paintings',
    },
  ])
)(Painting);
