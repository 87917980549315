import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

const Painting = ({ video }) => {
  return video ? (
    <div className='gallery-details'>
      <h1 className='lead text-center'>{video.title}</h1>
      <div className='gallery-img'>
        <div className='gallery-player'>
          <ReactPlayer
            controls
            url={video.videoURL}
            width='100%'
            height='100%'
          />
        </div>
      </div>
      <div className='gallery-footer'>
        <Link to='/kontakt' className='btn'>
          Kontakt
        </Link>
        <Link to='/videos' className='btn'>
          Zurück
        </Link>
      </div>
    </div>
  ) : (
    <h4>Loading...</h4>
  );
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const videos = state.firestore.data.videos;
  const video = videos ? videos[id] : null;
  return {
    video: video,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    {
      collection: 'videos',
    },
  ])
)(Painting);
