import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

import {
  updateBook,
  deleteBook,
  history,
} from '../../../redux/action/bookAction';

const EditBook = ({ updateBook, deleteBook, book, match, history }) => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    reading: '',
    pages: '',
    amazone: '',
    imageName: '',
    imageURL: '',
    pricePocketBook: '',
    priceHardBack: '',
    priceEbook: '',
    createdAt: '',
  });

  useEffect(() => {
    if (book) {
      setFormData({
        title: book.title,
        description: book.description,
        reading: book.reading,
        pages: book.pages,
        amazone: book.amazone,
        imageName: book.imageName,
        imageURL: book.imageURL,
        pricePocketBook: book.pricePocketBook,
        priceHardBack: book.priceHardBack,
        priceEbook: book.priceEbook,
        createdAt: book.createdAt,
      });
    }
  }, [book]);

  const {
    title,
    description,
    reading,
    pages,
    amazone,
    pricePocketBook,
    priceHardBack,
    priceEbook,
  } = formData;

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    const id = match.params.id;

    updateBook(formData, id);

    setFormData({
      title: '',
      description: '',
      reading: '',
      pages: '',
      amazone: '',
      imageName: '',
      imageURL: '',
      pricePocketBook: '',
      priceHardBack: '',
      priceEbook: '',
      createdAt: '',
    });
    history.push('/dashboard');
  };

  const handleDeleteBook = (e) => {
    e.preventDefault();

    const id = match.params.id;
    const imageName = formData.imageName;

    deleteBook(imageName, id);
    history.push('/dashboard');
  };

  return (
    <div className='dashboard-form'>
      <Link className='btn' to='/dashboard'>
        Zurück
      </Link>
      <h1 className='large text-center'>Buch bearbeiten</h1>
      <form className='form' onSubmit={onFormSubmit}>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Title'
            name='title'
            value={title}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <textarea
            type='text'
            placeholder='Beschreibung'
            name='description'
            value={description}
            onChange={(e) => onChange(e)}
            rows='5'
            cols='1'
          ></textarea>
        </div>
        <div className='form-group'>
          <textarea
            type='text'
            placeholder='Leseprobe'
            name='reading'
            value={reading}
            onChange={(e) => onChange(e)}
            rows='10'
            cols='1'
          ></textarea>
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Seiten'
            name='pages'
            value={pages}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Amazone-Link'
            name='amazone'
            value={amazone}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Preis für Taschenbuch'
            name='pricePocketBook'
            value={pricePocketBook}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Preis für gebundenes Buch'
            name='priceHardBack'
            value={priceHardBack}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Preis für Ebook'
            name='priceEbook'
            value={priceEbook}
            onChange={(e) => onChange(e)}
          />
        </div>
        <input type='submit' className='btn btn-primary' value='Senden' />
        <button className='btn btn-danger' onClick={handleDeleteBook}>
          Löschen
        </button>
      </form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const books = state.firestore.data.books;
  const book = books ? books[id] : null;
  return {
    book: book,
  };
};

export default compose(
  connect(mapStateToProps, { updateBook, deleteBook }),
  firestoreConnect([
    {
      collection: 'books',
    },
  ])
)(withRouter(EditBook));
