import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

const Reading = ({ book, match }) => {
  return book !== null ? (
    <div className='book-reading'>
      <div className='reading'>
        <h1 className='text-center lead'>{book.title}</h1>
        <pre>{book.reading}</pre>
      </div>
      <Link to={`/book/${match.params.id}`} className='btn'>
        Zurück
      </Link>
    </div>
  ) : (
    <h4>Loading...</h4>
  );
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const books = state.firestore.data.books;
  const book = books ? books[id] : null;
  return {
    book: book,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    {
      collection: 'books',
    },
  ])
)(Reading);
